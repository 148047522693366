import { LoadingIndicator } from '@tokensoft-web/common-ui';
import {
  getTxUrl,
  lt,
  useAnalytics,
  useModal,
  useNetworks,
  useToast,
  useTradeCompleted,
  useTradeFailed,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { VscLinkExternal } from 'react-icons/vsc';
import '../../pages/trading/trading.css';
import {
  useExecuteCrossChainQuote,
  useExecuteQuote,
  useGetAllowance,
  useHashflowRfq,
} from '../../services/hashflow-service';
import { TRADING_STATE } from '../../utils/enums';
import SelectGas from './select-gas';
import SelectToken from './select-token';
import TradeConfirmed from './trade-confirmed';
import TradeDetails from './trade-details';
import TradingMain from './trade-main';
import TradePending from './trade-pending';
import TradeRejected from './trade-rejected';

import { useTrading } from '../../contexts/trading/trading-context';
import TradingHeader from './trading-header';

const TradingApp = () => {
  const { pageEvent } = useAnalytics('/trade');

  const { showSuccessToast } = useToast();
  const { showModal, closeModal } = useModal();
  const { getNetworkDetails } = useNetworks();
  const { connectedChainId, switchChain } = useWallet();

  const [displayState, setDisplayState] = useState(TRADING_STATE.MAIN);

  const [error, setError] = useState<Error>();
  const [buttonState, setButtonState] = useState('approve');

  const { mutate: tradeCompleted } = useTradeCompleted();
  const { mutate: tradeFailed } = useTradeFailed();

  const {
    baseToken,
    setBaseToken,
    baseTokenAmount,
    setBaseTokenAmount,
    quoteToken,
    setQuoteToken,
    quoteTokenAmount,
    setQuoteTokenAmount,
    singleChainPairs,
    crossChainPairs,
    disableGas,
    dstNetworkId,
    refresh: refreshExchangeData,
    tokenInfo,
    selectedGas,
    crossChainTrade,
  } = useTrading();

  const { allowance, getAllowance, refreshAllowance } = useGetAllowance();

  const {
    quote,
    fee,
    executeRfqQuery,
    resetQuote,
    isFetching: quoteLoading,
    quoteExpired,
    quoteError,
    stopRefreshingQuote,
    forceBaseTokenRefresh,
  } = useHashflowRfq();

  const approvalRequired =
    !!allowance && !!quote
      ? lt(allowance, quote.baseTokenAmountWithFee)
      : undefined;

  const {
    tradeSingleHopReceipt,
    tradeSingleHopEventData,
    approvalData,
    approvalReceipt,
    awaitingApproval,
    awaitingApprovalReceipt,
    awaitingTrade,
    awaitingTradeReceipt,
    approvalError,
    approvalReceiptError,
    tradeSingleHopError,
    tradeSingleHopReceiptError,
    executeQuote,
    reset: resetExecuteQuote,
    makeTrade,
  } = useExecuteQuote();

  const {
    crossChainTradeReceipt,
    crossChainApprovalData,
    crossChainApprovalReceipt,
    crossChainTradeEventData,
    awaitingCrossChainApproval,
    awaitingCrossChainApprovalReceipt,
    awaitingCrossChainTrade,
    awaitingCrossChainTradeReceipt,
    crossChainTradeError,
    crossChainApprovalError,
    crossChainApprovalReceiptError,
    crossChainTradeReceiptError,
    executeCrossChainQuote,
    makeCrossChainTrade,
    reset: resetExecuteCrossChainQuote,
  } = useExecuteCrossChainQuote();

  const baseTokenInfo = tokenInfo[connectedChainId];
  const quoteTokenInfo =
    tokenInfo[crossChainTrade ? dstNetworkId : connectedChainId];

  // ************************ FUNCTIONS ************************

  const rejectTransactionHandler = () => {
    resetQuote();
    resetExecuteQuote();
    resetExecuteCrossChainQuote();
    setButtonState('approve');
    setBaseTokenAmount('');
    setQuoteTokenAmount('');
    setDisplayState(TRADING_STATE.MAIN);
    refreshExchangeData();
    closeModal();
  };

  const handleTransactionFinished = () => {
    resetQuote();
    setError(undefined);
    setButtonState('approve');
    resetExecuteQuote();
    resetExecuteCrossChainQuote();
    setBaseTokenAmount('');
    setQuoteTokenAmount('');
    setDisplayState(TRADING_STATE.MAIN);
    refreshExchangeData();
  };

  const handleMainButtonClick = () => {
    setDisplayState(TRADING_STATE.TRADE_PENDING);
    forceBaseTokenRefresh();
    crossChainTrade
      ? approvalRequired
        ? executeCrossChainQuote(
            dstNetworkId,
            quote,
            disableGas ? null : selectedGas,
          )
        : makeCrossChainTrade(quote, disableGas ? null : selectedGas)
      : approvalRequired
        ? executeQuote(quote, disableGas ? null : selectedGas)
        : makeTrade(quote, disableGas ? null : selectedGas);
  };

  const handleTradeButtonClick = () => {
    stopRefreshingQuote();
    pageEvent('trade', 'tradeRequest');
    crossChainTrade
      ? makeCrossChainTrade(quote, disableGas ? null : selectedGas)
      : makeTrade(quote, disableGas ? null : selectedGas);
  };

  const showTransactionRejectedErrorText = () => {
    return error
      ? error.message
      : approvalError
        ? approvalError
        : approvalReceiptError
          ? approvalReceiptError.message
          : tradeSingleHopError
            ? tradeSingleHopError.message
            : tradeSingleHopReceiptError
              ? tradeSingleHopReceiptError.message
              : crossChainTradeError
                ? crossChainTradeError.message
                : crossChainApprovalError
                  ? crossChainApprovalError.message
                  : crossChainApprovalReceiptError
                    ? crossChainApprovalReceiptError.message
                    : crossChainTradeReceiptError
                      ? crossChainTradeReceiptError.message
                      : 'Unknown Error';
  };

  const tradeButtonDisabled =
    quoteLoading ||
    buttonState === 'approve' ||
    (crossChainTrade
      ? awaitingCrossChainTrade || awaitingCrossChainTradeReceipt
      : awaitingTrade || awaitingTradeReceipt);

  const renderButtonText = () => {
    if (awaitingApproval || awaitingCrossChainApproval) {
      return `Awaiting ${baseToken} Approval...`;
    } else if (awaitingApprovalReceipt || awaitingCrossChainApprovalReceipt) {
      return `Approving ${baseToken}...`;
    } else if (awaitingTrade || awaitingCrossChainTrade) {
      return 'Awaiting Trade Approval...';
    } else if (awaitingTradeReceipt || awaitingCrossChainTradeReceipt) {
      return 'Awaiting Trade Confirmation...';
    } else if (quoteLoading) {
      return 'Fetching Quote...';
    } else if (
      crossChainTrade ? !crossChainApprovalReceipt : !approvalReceipt
    ) {
      return 'Trade';
    } else {
      return 'Execute Trade';
    }
  };

  // ************************ USE EFFECTS ************************

  useEffect(() => {
    pageEvent('trade', 'loadTrade');

    resetQuote();
    setDisplayState(TRADING_STATE.MAIN);
  }, []);

  useEffect(() => {
    if (approvalError) setError(approvalError);
    if (tradeSingleHopError) setError(tradeSingleHopError);
    if (crossChainApprovalError) setError(crossChainApprovalError);
    if (crossChainTradeError) setError(crossChainTradeError);
  }, [approvalError, tradeSingleHopError, crossChainApprovalError]);

  useEffect(() => {
    if (
      error ||
      approvalError ||
      approvalReceiptError ||
      tradeSingleHopError ||
      tradeSingleHopReceiptError ||
      crossChainTradeError ||
      crossChainApprovalError ||
      crossChainApprovalReceiptError ||
      crossChainTradeReceiptError
    ) {
      resetQuote();
      resetExecuteQuote();
      resetExecuteCrossChainQuote();
      setButtonState('approve');
      setDisplayState(TRADING_STATE.TRANS_REJECTED);
      tradeFailed('', {
        onSuccess: () => console.log('Trade failed send Success'),
        onError: () => console.log('Trade failed send Error'),
      });
    }
  }, [
    error,
    approvalError,
    approvalReceiptError,
    tradeSingleHopError,
    tradeSingleHopReceiptError,
    crossChainTradeError,
    crossChainApprovalError,
    crossChainApprovalReceiptError,
    crossChainTradeReceiptError,
  ]);

  useEffect(() => {
    if (approvalReceipt || crossChainApprovalReceipt) {
      showSuccessToast({
        description: (
          <div className='flex flex-row'>
            Successfully approved {quoteToken}.
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(
                approvalReceipt
                  ? approvalReceipt.transactionHash
                  : crossChainApprovalReceipt.transactionHash,
                getNetworkDetails(connectedChainId),
              )}
              className='w-[30px] flex items-center justify-center text-white'
              onClick={(e) => e.stopPropagation()}
            >
              <VscLinkExternal color='white' />
            </a>
          </div>
        ),
      });
    }
  }, [approvalReceipt, crossChainApprovalReceipt]);

  useEffect(() => {
    if (
      (crossChainTrade && crossChainTradeReceipt) ||
      (!crossChainTrade && tradeSingleHopReceipt)
    ) {
      setDisplayState(TRADING_STATE.TRADE_CONFIRMED);
      tradeCompleted(
        {
          tradeInfo: {
            baseToken,
            baseTokenAmount,
            quoteToken,
            quoteTokenAmount,
          },
        },
        {
          onSuccess: () => console.log('Trade completed send Success'),
          onError: () => console.log('Trade completed send Error'),
        },
      );
      pageEvent('trade', 'tradeConfirmed');
    }
  }, [tradeSingleHopReceipt, crossChainTradeReceipt]);

  useEffect(() => {
    if (
      quoteExpired &&
      !showTransactionRejectedErrorText() &&
      ((!crossChainTrade && awaitingApproval) ||
        awaitingTrade ||
        (crossChainTrade && awaitingCrossChainApproval) ||
        awaitingCrossChainTrade)
    ) {
      pageEvent('trade', 'tradeExpireMessage');
      showModal({
        onClose: rejectTransactionHandler,
        content: (
          <div>
            <div className='flex flex-col items-center'>
              <div className='rounded-full p-3 bg-amber-400 text-white'>
                <HiOutlineExclamationCircle size={50} />
              </div>
              <div className='flex justify-center mb-6 pt-3'>Quote Expired</div>
              <div className='w-full text-center text-sm text-neutral-medium mb-6'>
                Your quote has expired and will likely result in a failed
                transaction. Please reject any pending transactions in your
                wallet and try again.
              </div>
              <button
                className='btn btn-primary w-full'
                onClick={rejectTransactionHandler}
              >
                Close
              </button>
            </div>
          </div>
        ),
      });
    }
  }, [quoteExpired]);

  useEffect(() => {
    if (quoteToken) {
      pageEvent('trade', 'setQuoteToken');
    }
  }, [quoteToken]);

  useEffect(() => {
    if (baseToken) {
      pageEvent('trade', 'setBaseToken');
    }
  }, [baseToken]);

  useEffect(() => {
    if (baseToken && tokenInfo?.[connectedChainId]?.[baseToken]) {
      getAllowance(tokenInfo[connectedChainId][baseToken].address);
    }
  }, [baseToken, tokenInfo, connectedChainId]);

  useEffect(() => {
    if (baseTokenAmount) {
      pageEvent('trade', 'setBaseTokenAmount');
    }
  }, [baseTokenAmount]);

  useEffect(() => {
    if (quoteTokenAmount) {
      pageEvent('trade', 'setQuoteTokenAmount');
    }
  }, [quoteTokenAmount]);

  useEffect(() => {
    if (awaitingApproval) {
      pageEvent('trade', 'approvalRequest');
    }
  }, [awaitingApproval]);

  useEffect(() => {
    if (approvalReceipt) {
      pageEvent('trade', 'approvalConfirmed');
    }
  }, [approvalReceipt]);

  useEffect(() => {
    if (approvalError) {
      pageEvent('trade', 'approvalFailed');
    }
  }, [approvalError]);

  useEffect(() => {
    if (tradeSingleHopReceiptError || crossChainTradeReceiptError) {
      pageEvent('trade', 'tradeFailed');
    }
  }, [tradeSingleHopReceiptError, crossChainTradeReceiptError]);

  const noTradingPairModal = {
    onClose: rejectTransactionHandler,
    content: (
      <div>
        <div className='flex flex-col items-center'>
          <div className='rounded-full p-3 bg-amber-400 text-white'>
            <HiOutlineExclamationCircle size={50} />
          </div>
          <div className='w-full text-center text-sm text-neutral-medium pt-3 mb-6'>
            The trading pair is no longer available.
          </div>
          <button
            className='btn btn-primary w-full'
            onClick={rejectTransactionHandler}
          >
            Close
          </button>
        </div>
      </div>
    ),
  };

  useEffect(() => {
    if (baseToken) {
      if (crossChainTrade) {
        if (quoteToken) {
          if (!crossChainPairs[dstNetworkId][baseToken].has(quoteToken)) {
            setQuoteToken([...crossChainPairs[dstNetworkId][baseToken]][0]);
            if (quote) {
              showModal(noTradingPairModal);
            }
            setDisplayState(TRADING_STATE.MAIN);
          }
        }
      } else {
        if (quoteToken) {
          if (!singleChainPairs[baseToken].includes(quoteToken)) {
            setQuoteToken(singleChainPairs[baseToken][0]);
            if (quote) {
              showModal(noTradingPairModal);
            }
            setDisplayState(TRADING_STATE.MAIN);
          }
        } else {
          setQuoteToken(singleChainPairs[baseToken][0]);
        }
      }
    }
  }, [baseToken]);

  useEffect(() => {
    const isNativeTx =
      baseTokenInfo?.[baseToken]?.address ===
      '0x0000000000000000000000000000000000000000';
    if (isNativeTx) {
      setButtonState('trade');
    }
  }, [baseToken]);

  useEffect(() => {
    if (tradeSingleHopReceipt || crossChainTradeReceipt) {
      refreshExchangeData();
      refreshAllowance();
    }
  }, [tradeSingleHopReceipt, crossChainTradeReceipt]);

  useEffect(() => {
    if (crossChainTrade) {
      if (crossChainApprovalReceipt) {
        setButtonState('trade');
        refreshAllowance();
      }
    } else {
      if (approvalReceipt) {
        setButtonState('trade');
        refreshAllowance();
      }
    }
  }, [approvalReceipt, crossChainApprovalReceipt]);

  useEffect(() => {
    if (crossChainTrade && crossChainPairs[dstNetworkId]) {
      if (crossChainPairs[dstNetworkId][baseToken]) {
        if (!crossChainPairs[dstNetworkId][baseToken].has(quoteToken)) {
          setQuoteToken([...crossChainPairs[dstNetworkId][baseToken]][0]);
        }
      } else {
        const newBaseToken = Object.keys(crossChainPairs[dstNetworkId])[0];
        setBaseToken(newBaseToken);
      }
    } else {
      if (baseToken && !singleChainPairs[baseToken].includes(quoteToken)) {
        setQuoteToken(singleChainPairs[baseToken][0]);
      }
    }

    resetQuote();
  }, [dstNetworkId]);

  useEffect(() => {
    if (!crossChainTrade && singleChainPairs) {
      if (baseToken) {
        if (singleChainPairs[baseToken]) {
          if (!singleChainPairs[baseToken].includes(quoteToken)) {
            setQuoteToken(singleChainPairs[baseToken][0]);
          }
        } else {
          const newBaseToken = Object.keys(singleChainPairs)[0];
          setBaseToken(newBaseToken);
        }
      } else {
        const newBaseToken = Object.keys(singleChainPairs)[0];
        setBaseToken(newBaseToken);
      }
    }
  }, [singleChainPairs]);

  const renderTradingContent = (displayState: TRADING_STATE) => {
    switch (displayState) {
      case TRADING_STATE.MAIN:
        return (
          <TradingMain
            setDisplayState={setDisplayState}
            resetQuote={resetQuote}
            quote={quote}
            quoteLoading={quoteLoading}
            quoteError={quoteError}
            onMainButtonClick={handleMainButtonClick}
            executeRfqQuery={executeRfqQuery}
          />
        );

      case TRADING_STATE.SELECT_BASE_TOKEN:
      case TRADING_STATE.SELECT_QUOTE_TOKEN:
        return (
          <SelectToken
            tokenInfo={
              displayState === TRADING_STATE.SELECT_BASE_TOKEN
                ? baseTokenInfo
                : quoteTokenInfo
            }
            isBaseToken={displayState === TRADING_STATE.SELECT_BASE_TOKEN}
            setDisplayState={setDisplayState}
            resetQuote={resetQuote}
          />
        );

      case TRADING_STATE.GAS:
        return <SelectGas setDisplayState={setDisplayState} />;

      case TRADING_STATE.TRADE_PENDING:
        return (
          <TradePending
            renderButtonText={renderButtonText}
            tradeButtonDisabled={tradeButtonDisabled}
            onTradeButtonClick={handleTradeButtonClick}
            quote={quote}
            onCancelTrade={handleTransactionFinished}
            cancelTradeDisabled={
              awaitingTradeReceipt || awaitingCrossChainTradeReceipt
            }
          />
        );

      case TRADING_STATE.TRADE_CONFIRMED:
        return (
          <TradeConfirmed
            tradeSingleHopEventData={tradeSingleHopEventData}
            crossChainTradeEventData={crossChainTradeEventData}
            setDisplayState={setDisplayState}
            onTransactionFinished={handleTransactionFinished}
          />
        );

      case TRADING_STATE.TRANS_REJECTED:
        return (
          <TradeRejected
            handleTransactionFinished={handleTransactionFinished}
            showTransactionRejectedErrorText={showTransactionRejectedErrorText}
          />
        );

      case TRADING_STATE.TRADE_DETAILS:
        return (
          <TradeDetails
            baseTokenInfo={baseTokenInfo}
            quoteTokenInfo={quoteTokenInfo}
            tradeSingleHopEventData={tradeSingleHopEventData}
            crossChainTradeEventData={crossChainTradeEventData}
            handleTransactionFinished={handleTransactionFinished}
            quote={quote}
            resetQuote={resetQuote}
            crossChainTradeReceipt={crossChainTradeReceipt}
          />
        );

      default:
        return null;
    }
  };

  if (!baseToken || !quoteToken) {
    return <LoadingIndicator text={'Loading Trading'} />;
  }

  return (
    <>
      <TradingHeader />
      <div className='flex justify-center flex-col max-w-lg'>
        {renderTradingContent(displayState)}
        <div className='mt-4 text-xs'>
          <p className='text-center'>
            Please make sure to submit your feedback via the{' '}
            <a
              className='underline'
              href={'https://tokensoft.typeform.com/to/WzsBCsj0'}
              target='_blank'
            >
              Tokensoft Trading: Beta Release Feedback form
            </a>
            . You're also invited to join our{' '}
            <a
              className='underline'
              href={'https://t.me/+LqY30D8ZRng2N2Ex'}
              target='_blank'
            >
              Early Access: Tokensoft Trading Telegram Group
            </a>
            . However, the size of this group is limited and may fill.
          </p>
        </div>
      </div>
    </>
  );
};

export default TradingApp;
