import { EventLayout } from '@tokensoft-web/common-ui/src/components/ui/layout/event-layout';
import { Layout } from '@tokensoft-web/common-ui/src/components/ui/layout/layout';
import { useConfiguration } from '@tokensoft-web/common-utils';
import {
  Navigate,
  Outlet,
  Route as ReactRoute,
  Routes as ReactRoutes,
  useLocation,
} from 'react-router-dom';
import { AccreditationCompleted } from './components/accreditation/accreditation-completed';
import { AccreditationFailed } from './components/accreditation/accreditation-failed';
import { AccreditationPending } from './components/accreditation/accreditation-pending';
import App from './components/app';
import { IdentityCompleted } from './components/identity/identity-completed';
import { IdentityFailed } from './components/identity/identity-failed';
import { IdentityPending } from './components/identity/identity-pending';
import { IdentitySubmitted } from './components/identity/identity-submitted';
import { ContractProvider } from './contexts/contract/contract-provider';
import { EventMeProvider } from './contexts/event/event-me-provider';
import { EventProvider } from './contexts/event/event-provider';
import { ProfileProvider } from './contexts/profile/profile-provider';
import { ProjectProvider } from './contexts/project/project-provider';
import AccreditationPage from './pages/account/accreditation';
import IdentityPage from './pages/account/identity';
import ProfileDashboard from './pages/account/profile-dashboard';
import ViewMessagePage from './pages/account/view-message';
import AdminPage from './pages/admin/admin';
import { DeployDistributorWizard } from './pages/admin/distributors/deploy-distributor-wizard';
import { EditDistributorFundWizard } from './pages/admin/distributors/edit-distributor-fund-wizard';
import { EditDistributorOwnerWizard } from './pages/admin/distributors/edit-distributor-owner-wizard';
import { EditDistributorParticipantsWizard } from './pages/admin/distributors/edit-distributor-participants-wizard';
import { EditDistributorVestingWizard } from './pages/admin/distributors/edit-distributor-vesting-wizard';
import { EditEventContentWizard } from './pages/admin/events/edit-event-content-wizard';
import EventEditDetailsPage from './pages/admin/events/edit-event-details';
import { EditEventDocumentsWizard } from './pages/admin/events/edit-event-documents-wizard';
import { EditEventEligibilityWizard } from './pages/admin/events/edit-event-eligibility-wizard';
import { EditEventGeneralWizard } from './pages/admin/events/edit-event-general-wizard';
import { EditEventPrivacyWizard } from './pages/admin/events/edit-event-privacy-wizard';
import EventCreate from './pages/admin/events/event-create';
import EventDashboardPage from './pages/admin/events/event-dashboard';
import { LaunchDistributorEventWizard } from './pages/admin/events/launch-distributor-event-wizard';
import { LaunchIdentityEventWizard } from './pages/admin/events/launch-identity-event-wizard';
import { LaunchSaleEventWizard } from './pages/admin/events/launch-sale-event-wizard';
import { EditProjectThemeWizard } from './pages/admin/project/edit-project-theme-wizard';
import ProjectDashboardPage from './pages/admin/project/project-dashboard';
import { RegisterPage } from './pages/admin/register/register';
import { DeploySaleWizard } from './pages/admin/sales/deploy-sale-wizard';
import { EditSaleOwnerWizard } from './pages/admin/sales/edit-sale-owner-wizard';
import { EditSaleParticipantsWizard } from './pages/admin/sales/edit-sale-participants-wizard';
import { EditSalePaymentConfigWizard } from './pages/admin/sales/edit-sale-payment-config-wizard';
import { EditSaleSetupWizard } from './pages/admin/sales/edit-sale-setup-wizard';
import { UserPage } from './pages/admin/user/user-page';
import ClaimsPage from './pages/claims/claims';
import Dashboard from './pages/dashboard';
import ErrorPage from './pages/error-page';
import EventPage from './pages/event/event';
import DelegatePage from './pages/governance/delegate/delegate-page';
import Governance from './pages/governance/governance';
import MaintenancePage from './pages/maintenance-page';
import ProjectPolicy from './pages/project-policy';
import ProjectsPage from './pages/project/projects';
import Purchases from './pages/purchases';
import TradingPage from './pages/trading/trading';

/**
 * Note: there seems to be a timing issue where the configuration data
 * may not have arrived before the isAllowed condition is evaluated.
 * This results in users being incorrectly redirected to the dashboard.
 *
 * We will need to look into this before re-enabling this logic.
 */
const ProtectedRoute = ({ isAllowed, redirectPath = '/', children = null }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const RedirectRoute = ({ redirectPath = '/', children = null }) => {
  const { pathname } = useLocation();
  const { configuration } = useConfiguration();

  if (
    configuration?.project?.defaultUri &&
    (pathname === '/' || pathname === '')
  ) {
    return <Navigate to={configuration?.project?.defaultUri} replace />;
  }

  return children ? children : <Outlet />;
};

export const Routes = () => {
  return (
    <ReactRoutes>
      <ReactRoute path='/policy' element={<ProjectPolicy />} />
      <ReactRoute path='/maintenance' element={<MaintenancePage />} />

      <ReactRoute path='/' element={<App />}>
        <ReactRoute element={<RedirectRoute />}>
          {/* Default route */}
          <ReactRoute
            path=''
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />

          <ReactRoute
            path='/purchases'
            element={
              <Layout>
                <Purchases />
              </Layout>
            }
          />

          {/* Event routes */}
          <ReactRoute
            path='/event/:eventId'
            element={
              <EventLayout>
                <EventMeProvider>
                  <EventPage />
                </EventMeProvider>
              </EventLayout>
            }
          />

          {/* Trade routes */}
          <ReactRoute
            path='/trade'
            element={
              <Layout>
                <TradingPage />
              </Layout>
            }
          />

          {/* Claim routes */}
          <ReactRoute path='/claim'>
            <ReactRoute
              path=''
              element={
                <Layout>
                  <ClaimsPage />
                </Layout>
              }
            />
            <ReactRoute
              path='*'
              element={
                <Layout>
                  <ClaimsPage />
                </Layout>
              }
            />
          </ReactRoute>

          {/* Governance routes */}
          <ReactRoute
            path='/governance/'
            element={
              <Layout>
                <Governance />
              </Layout>
            }
          />
          <ReactRoute
            path='/governance/:contractId'
            element={
              <Layout>
                <ContractProvider>
                  <DelegatePage />
                </ContractProvider>
              </Layout>
            }
          />

          {/* Account routes */}
          <ReactRoute path='/account'>
            <ReactRoute
              path=''
              element={
                <Layout>
                  <ProfileProvider>
                    <ProfileDashboard />
                  </ProfileProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='identity'
              element={
                <Layout>
                  <IdentityPage />
                </Layout>
              }
            />
            <ReactRoute
              path='identity/submitted'
              element={
                <Layout>
                  <IdentitySubmitted />
                </Layout>
              }
            />
            <ReactRoute
              path='identity/pending'
              element={
                <Layout>
                  <IdentityPending />
                </Layout>
              }
            />
            <ReactRoute
              path='identity/completed'
              element={
                <Layout>
                  <IdentityCompleted />
                </Layout>
              }
            />
            <ReactRoute
              path='identity/failed'
              element={
                <Layout>
                  <IdentityFailed />
                </Layout>
              }
            />
            <ReactRoute
              path='accreditation'
              element={
                <Layout>
                  <AccreditationPage />
                </Layout>
              }
            />
            <ReactRoute
              path='accreditation/pending'
              element={
                <Layout>
                  <AccreditationPending />
                </Layout>
              }
            />
            <ReactRoute
              path='accreditation/completed'
              element={
                <Layout>
                  <AccreditationCompleted />
                </Layout>
              }
            />
            <ReactRoute
              path='accreditation/failed'
              element={
                <Layout>
                  <AccreditationFailed />
                </Layout>
              }
            />
            <ReactRoute
              path='message/:messageToken'
              element={
                <Layout>
                  <ViewMessagePage />
                </Layout>
              }
            />
            <ReactRoute
              path='*'
              element={
                <Layout>
                  <ProfileProvider>
                    <ProfileDashboard />
                  </ProfileProvider>
                </Layout>
              }
            />
          </ReactRoute>

          {/* Project routes */}
          <ReactRoute
            path='/projects'
            element={
              <Layout>
                <ProjectsPage />
              </Layout>
            }
          />

          {/* Catch-all */}
          <ReactRoute
            path='*'
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />

          {/* Admin routes */}
          <ReactRoute path='/admin'>
            <ReactRoute
              path=''
              element={
                <Layout>
                  <AdminPage />
                </Layout>
              }
            />
            <ReactRoute
              path='register'
              element={
                <Layout fullWidth>
                  <RegisterPage />
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id'
              element={
                <Layout>
                  <ProjectProvider>
                    <ProjectDashboardPage />
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/theme'
              element={
                <Layout>
                  <ProjectProvider>
                    <EditProjectThemeWizard />
                  </ProjectProvider>
                </Layout>
              }
            />

            <ReactRoute
              path='project/:id/event/launch'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventCreate />
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/launch/identity'
              element={
                <Layout fullWidth>
                  <ProjectProvider>
                    <LaunchIdentityEventWizard />
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/launch/sale'
              element={
                <Layout fullWidth>
                  <ProjectProvider>
                    <LaunchSaleEventWizard />
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/launch/distributor'
              element={
                <Layout fullWidth>
                  <ProjectProvider>
                    <LaunchDistributorEventWizard />
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EventDashboardPage />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/fund'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditDistributorFundWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/user/:authId'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <UserPage />
                    </EventProvider>
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/edit'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EventEditDetailsPage />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/general'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditEventGeneralWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/eligibility'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditEventEligibilityWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/privacy'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditEventPrivacyWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />

            <ReactRoute
              path='project/:id/event/:eventId/documents'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditEventDocumentsWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/content'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditEventContentWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/sale/participants'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditSaleParticipantsWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/sale/owner'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditSaleOwnerWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/sale/payment-config'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditSalePaymentConfigWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/sale/setup'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditSaleSetupWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/distributor/owner'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditDistributorOwnerWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/distributor/participants'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditDistributorParticipantsWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/distributor/vesting'
              element={
                <Layout>
                  <ProjectProvider>
                    <EventProvider>
                      <EditDistributorVestingWizard />
                    </EventProvider>{' '}
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/deploy/distributor'
              element={
                <Layout fullWidth>
                  <ProjectProvider>
                    <EventProvider>
                      <DeployDistributorWizard />
                    </EventProvider>
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='project/:id/event/:eventId/deploy/sale'
              element={
                <Layout fullWidth>
                  <ProjectProvider>
                    <EventProvider>
                      <DeploySaleWizard />
                    </EventProvider>
                  </ProjectProvider>
                </Layout>
              }
            />
            <ReactRoute
              path='*'
              element={
                <Layout>
                  <AdminPage />
                </Layout>
              }
            />
          </ReactRoute>

          {/* Misc */}
          <ReactRoute path='error' element={<ErrorPage />} />

          {/* Catch-all */}
          <ReactRoute path='*' element={<ErrorPage />} />
        </ReactRoute>
      </ReactRoute>
    </ReactRoutes>
  );
};
