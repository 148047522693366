import { ButtonRow } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import TradingHeader from './trading-header';

const TradingWalletRestriction = () => {
  const { pageEvent } = useAnalytics();
  pageEvent('trade', 'loadTradeRequestAccess');

  return (
    <>
      <TradingHeader />

      <div className='mb-12'>
        <div>
          <img
            className={'mb-12'}
            src='https://s3.amazonaws.com/media.tokensoft.io/images/trading+banner.png'
            alt={'Tokensoft Trading'}
          />
          <p className={'text-2xl font-bold mb-8'}>
            Trading is coming to Tokensoft.
          </p>
          <p className={'mb-8'}>
            <b>Note:</b> early access slots are limited and eligibility may be
            subject to geographical or verification restrictions.
          </p>
        </div>
      </div>

      <ButtonRow place={'start'}>
        <a
          className='btn btn-primary p-12'
          href={'https://tokensoft.typeform.com/to/SOORoqkv'}
          target={'_blank'}
        >
          <span className='flex items-center'>Request Access</span>
        </a>
      </ButtonRow>
    </>
  );
};

export default TradingWalletRestriction;
