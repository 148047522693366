import { Card } from '@tokensoft-web/common-ui';
import { HiOutlineExclamation } from 'react-icons/hi';
import '../../pages/trading/trading.css';

const TradeRejected = ({
  showTransactionRejectedErrorText,
  handleTransactionFinished,
}) => {
  return (
    <Card className='w-128 exchange-submitted-pending flex flex-col high-contrast'>
      <div className='flex justify-center items-center mt-8'>
        <div className='rounded-full p-3 bg-danger-medium text-white'>
          <HiOutlineExclamation size={50} />
        </div>
      </div>
      <div className='flex justify-center mb-6 pt-3'>Transaction Rejected</div>
      <div className='w-full text-center text-sm text-neutral-medium mb-6 capitalize'>
        {`Reason: ${showTransactionRejectedErrorText()}`}
      </div>
      <div>
        <button
          onClick={handleTransactionFinished}
          className='btn btn-primary w-full'
        >
          Continue
        </button>
      </div>
    </Card>
  );
};

export default TradeRejected;
