import { Card, CardNote } from '@tokensoft-web/common-ui';
import {
  NULL_ADDRESS,
  convertBaseUnitsToDecimal,
  formatValue,
  getTruncatedAddress,
  useAuth,
  useConfiguration,
  useNetworks,
  useWallet,
} from '@tokensoft-web/common-utils';
import { BiLinkExternal } from 'react-icons/bi';
import { BsPatchExclamation } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { getAddressUrl } from '../../utils/network';
import { DelegateIcon } from '../claims/delegate-icon';
import './contract-card.css';

interface ContractCardProps {
  contract: any;
  id?: string;
}

const ContractCard = ({ id, contract }: ContractCardProps) => {
  const { configuration } = useConfiguration();
  const { getNetworkDetails } = useNetworks();
  const { connectedChainId, switchChain } = useWallet();
  const {
    user: { walletAddress },
  } = useAuth();
  const networkDetails = getNetworkDetails(contract.networkId);

  const renderCardFooter = (contract) => {
    let text = '';
    let hasIcon = false;

    if (contract.userContractMetadata) {
      if (parseInt(contract.userContractMetadata?.balance)) {
        if (
          contract.userContractMetadata.delegateAddress &&
          contract.userContractMetadata.delegateAddress !== NULL_ADDRESS &&
          contract.userContractMetadata.delegateAddress !== '0x'
        ) {
          text = `${formatValue(
            convertBaseUnitsToDecimal(
              contract.userContractMetadata.balance,
              contract.tokenDecimals,
              4,
            ),
            { commas: true },
          )} Votes are delegated to ${getTruncatedAddress(
            contract.userContractMetadata.delegateAddress,
          )}`;
        } else {
          text = `You need to select a delegate for these votes`;
          hasIcon = true;
        }
      } else {
        text = `You do not hold any ${
          contract.tokenSymbol?.toUpperCase() || '-'
        } tokens on ${networkDetails.name}`;
      }
    } else {
      text = `You do not hold any ${
        contract.tokenSymbol?.toUpperCase() || '-'
      } tokens on ${networkDetails.name}`;
    }

    return (
      <CardNote
        icon={
          hasIcon ? (
            <BsPatchExclamation className='text-orange-400' size={20} />
          ) : null
        }
        text={text}
      />
    );
  };

  const renderVotes = () => {
    if (contract.userContractMetadata) {
      if (
        parseInt(contract.userContractMetadata?.votes) &&
        contract.userContractMetadata.delegateAddress.toLowerCase() ===
          walletAddress.toLowerCase()
      ) {
        return `${formatValue(
          convertBaseUnitsToDecimal(
            contract.userContractMetadata.votes,
            contract.tokenDecimals,
            4,
          ),
          { commas: true },
        )} Votes`;
      }

      if (parseInt(contract.userContractMetadata?.balance)) {
        return (
          <span className='text-neutral-400'>{`${formatValue(
            convertBaseUnitsToDecimal(
              contract.userContractMetadata.balance,
              contract.tokenDecimals,
              4,
            ),
            { commas: true },
          )} Votes`}</span>
        );
      }
    }

    return <span className='text-neutral-400'>0 Votes</span>;
  };

  const renderActions = () => {
    if (connectedChainId != contract.networkId) {
      return (
        <button
          data-testid={`${id}-action`}
          className={`btn btn-outline-primary !flex items-center gap-2 !text-sm w-full justify-center`}
          onClick={() => switchChain(contract.networkId)}
        >
          {`Switch to ${networkDetails.name}`}
        </button>
      );
    }

    if (contract.userContractMetadata) {
      if (parseInt(contract.userContractMetadata?.balance)) {
        if (
          contract.userContractMetadata.delegateAddress &&
          contract.userContractMetadata.delegateAddress !== NULL_ADDRESS &&
          contract.userContractMetadata.delegateAddress !== '0x'
        ) {
          return (
            <Link
              data-testid={`${id}-action`}
              className={`btn btn-outline-primary !flex items-center gap-2 !text-sm w-full justify-center`}
              to={`/governance/${contract.address}`}
            >
              <DelegateIcon /> Edit Delegate
            </Link>
          );
        } else {
          return (
            <Link
              data-testid={`${id}-action`}
              className={`btn btn-primary !flex items-center gap-2 !text-sm w-full justify-center`}
              to={`/governance/${contract.address}`}
            >
              <DelegateIcon /> Delegate Votes
            </Link>
          );
        }
      } else {
        return (
          <Link
            data-testid={`${id}-action`}
            className={`btn btn-primary !flex items-center gap-2 !text-sm w-full justify-center`}
            to={`/governance/${contract.address}`}
          >
            <DelegateIcon /> Delegate Votes
          </Link>
        );
      }
    }

    return (
      <button
        data-testid={`${id}-action`}
        disabled
        className={`btn btn-dark !flex items-center gap-2 !text-sm w-full justify-center`}
      >
        <DelegateIcon /> Delegate Votes
      </button>
    );
  };

  return (
    <Card
      id={`${id}-card`}
      className='instance relative m-4'
      footer={renderCardFooter(contract)}
    >
      <div className='flex-row flex items-center'>
        <div
          data-testid={`${id}-votes`}
          className='font-semibold text-3xl flex-grow'
        >
          {renderVotes()}
        </div>
        <div className='flex flex-col w-36 text-xs font-semibold'>
          <div>NETWORK:</div>
          <div data-testid={`${id}-network`} className='flex mt-1'>
            <div className='mr-1'>
              <img src={networkDetails.logoUri} width='18' alt='' />
            </div>
            <div className=''>{networkDetails.name.toUpperCase()}</div>
          </div>
        </div>
        <div className='flex flex-col w-36 text-xs font-semibold'>
          <div>TOKEN:</div>
          <div data-testid={`${id}-token`} className='flex mt-1 items-center'>
            <div className='mr-1'>
              <img
                src={
                  contract.tokenLogoUri
                    ? contract.tokenLogoUri
                    : configuration.project?.theme?.logoSmall
                      ? configuration.project.theme.logoSmall
                      : ''
                }
                width='18'
                alt=''
              />
            </div>
            <div className=''>{contract.tokenSymbol?.toUpperCase() || '-'}</div>
            <a
              href={getAddressUrl(contract.address, networkDetails)}
              target='_blank'
              className='flex items-center text-sm'
            >
              <BiLinkExternal className='ml-2' size={14} />
            </a>
          </div>
        </div>
        <div className='w-48'>{renderActions()}</div>
      </div>
    </Card>
  );
};

export default ContractCard;
