import { Card, InputGroup } from '@tokensoft-web/common-ui';
import { fixAndTrim, mult } from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { useTrading } from '../../contexts/trading/trading-context';
import '../../pages/trading/trading.css';
import { TRADING_STATE } from '../../utils/enums';

const SelectToken = ({
  tokenInfo,
  isBaseToken,
  setDisplayState,
  resetQuote,
}) => {
  const {
    tokenList,
    baseToken,
    setBaseToken,
    setBaseTokenAmount,
    singleChainPairs,
    quoteToken,
    setQuoteToken,
    setQuoteTokenAmount,
  } = useTrading();

  const [searchText, setSearchText] = useState('');

  const handleInput = (event) => {
    setSearchText(event.target.value);
  };

  const handleTokenSelect = (newTokenSymbol: string) => {
    if (isBaseToken) {
      if (baseToken !== newTokenSymbol) {
        setBaseToken(newTokenSymbol);
        if (!singleChainPairs[newTokenSymbol].includes(quoteToken)) {
          setQuoteToken(singleChainPairs[newTokenSymbol][0]);
        }
        setBaseTokenAmount('');
        setQuoteTokenAmount('');
        resetQuote();
      }
    } else {
      if (quoteToken !== newTokenSymbol) {
        setQuoteToken(newTokenSymbol);
        setBaseTokenAmount('');
        setQuoteTokenAmount('');
        resetQuote();
      }
    }

    setDisplayState(TRADING_STATE.MAIN);
  };

  return (
    <Card className='w-128 exchange-token'>
      <div className='flex items-center flex-grow title-text'>SELECT TOKEN</div>
      <div className='flex flex-col'>
        <InputGroup
          type='search'
          value={searchText}
          name='searchText'
          onChange={handleInput}
          placeholder='Enter Symbol'
        />
        <div className='max-h-128 h-auto overflow-hidden mb-5 relative'>
          <div className='max-h-128 flex flex-col overflow-auto py-3'>
            {tokenList.filter((symbol) =>
              symbol
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase()),
            ).length == 0 ? (
              <div className='w-full flex justify-center high-contrast'>
                No Token Found
              </div>
            ) : (
              tokenList
                .filter(
                  (symbol) =>
                    symbol
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase()) &&
                    tokenInfo[symbol],
                )
                .map((symbol, index) => (
                  <div
                    className='flex py-2 px-4 rounded token-cols hover:cursor-pointer'
                    onClick={() => handleTokenSelect(symbol)}
                    key={index}
                  >
                    <div className='flex justify-center items-center pr-3'>
                      {tokenInfo[symbol].logo ? (
                        <>
                          <img
                            className='rounded-full'
                            src={`${tokenInfo[symbol].logo}`}
                            width='38'
                            height='38'
                          />
                        </>
                      ) : (
                        <div className='no-logo-token bg-neutral-medium rounded-full flex items-center justify-center text-lg'>
                          {symbol.charAt(0)}
                        </div>
                      )}
                    </div>
                    <div className='flex flex-grow justify-between'>
                      <div className='flex flex-col text-sm'>
                        <div className='high-contrast text-xl'>{symbol}</div>
                        <div className='alt-text text-neutral-medium'>
                          {tokenInfo[symbol]?.name || '--'}
                        </div>
                      </div>
                      <div className='flex flex-col text-sm'>
                        <div className='high-contrast text-xl flex justify-end'>
                          {fixAndTrim(tokenInfo[symbol]?.balance || '0', 6) ||
                            '0'}
                        </div>
                        <div className='alt-text text-neutral-medium flex justify-end'>
                          {tokenInfo[symbol]?.balance &&
                          tokenInfo[symbol]?.priceUsd
                            ? `$${mult(
                                tokenInfo[symbol].balance,
                                tokenInfo[symbol].priceUsd,
                                2,
                              )}`
                            : '--'}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
        <button
          onClick={() => setDisplayState(TRADING_STATE.MAIN)}
          className='btn btn-dark'
        >
          Cancel
        </button>
      </div>
    </Card>
  );
};

export default SelectToken;
