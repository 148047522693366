import { MultiSelect } from '@tokensoft-web/common-ui';
import { useNetworks } from '@tokensoft-web/common-utils';

const NetworkDropdown = ({
  networkOptionIds,
  selectedNetworkId,
  onNetworkChange,
  className = '',
  isSearchable = false,
}) => {
  const { getNetworkDetails } = useNetworks();

  const networkOptions = networkOptionIds.map((id) => {
    const network = getNetworkDetails(id);

    return {
      label: (
        <div className='flex text-base items-center network'>
          <img
            src={network.logoUri}
            className='rounded-full mr-3'
            width='24'
            height='24'
          />
          {network.name}
        </div>
      ),
      value: id,
    };
  });

  return (
    <div className='text-neutral-medium text-xs'>
      <div className='flex wide'>
        <MultiSelect
          options={networkOptions}
          value={networkOptions.find(
            (network) => network.value === selectedNetworkId,
          )}
          onChange={onNetworkChange}
          isMulti={false}
          className={className}
          isSearchable={isSearchable}
        />
      </div>
    </div>
  );
};

export default NetworkDropdown;
