import { Card } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiOutlineCheck } from 'react-icons/hi';
import { useTrading } from '../../contexts/trading/trading-context';
import '../../pages/trading/trading.css';
import { TRADING_STATE } from '../../utils/enums';

const TradeConfirmed = ({
  tradeSingleHopEventData,
  crossChainTradeEventData,
  setDisplayState,
  onTransactionFinished,
}) => {
  const { pageEvent } = useAnalytics();
  const { crossChainTrade } = useTrading();

  const eventDataPending = crossChainTrade
    ? !crossChainTradeEventData
    : !tradeSingleHopEventData;

  const handleClick = () => {
    pageEvent('trade', 'tradeAgain');
    onTransactionFinished();
  };

  return (
    <Card className='w-128 exchange-submitted-pending flex flex-col high-contrast'>
      <div className='flex justify-center items-center mt-8'>
        <div className='rounded-full p-3 bg-success-medium text-white'>
          <HiOutlineCheck size={50} />
        </div>
      </div>
      <div className='flex justify-center pt-3'>Transaction Completed</div>
      <div className='text-xs text-neutral-medium mt-6 mb-9 px-9 text-center'>
        It may take a minute for your wallet to reflect the updated balances.
      </div>
      <div className='flex space-x-4 justify-center gap-5'>
        <button
          disabled={eventDataPending}
          onClick={() => {
            pageEvent('trade', 'viewReceipt');
            setDisplayState(TRADING_STATE.TRADE_DETAILS);
          }}
          className='btn btn-outline-primary grow'
        >
          {eventDataPending ? (
            <div className='flex w-full items-center justify-center'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>
              <span className='pl-2'>Awaiting Receipt</span>
            </div>
          ) : (
            'View Receipt'
          )}
        </button>
        <button className='btn btn-primary grow' onClick={handleClick}>
          New Trade
        </button>
      </div>
    </Card>
  );
};

export default TradeConfirmed;
