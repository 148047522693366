import { useQueries, useQuery } from '@tanstack/react-query';
import {
  hexToDecimal,
  isEmptyObject,
  useAsync,
  useAuth,
  useInterval,
  useTradingApiClient,
  useWallet,
} from '@tokensoft-web/common-utils';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import {
  useBalance,
  useReadContract,
  useReadContracts,
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWriteContract,
} from 'wagmi';
import Erc20Definition from '../contracts/Erc20.json';
import HashflowCrossChainReceivedDefinition from '../contracts/HashflowCrossChainReceived.json';
import TraderDefinition from '../contracts/Trader.json';

const HASHFLOW_NETWORK_IDS = [
  1, // mainnet
  137, // polygon
  43114, // avalanche
  56, // bsc
  42161, // arbitrum
  10, // optimism
];

const HASHFLOW_TEST_NETWORK_IDS = [
  80001, // mumbai
  97, // rialto
];

const HASHFLOW_ROUTER_ADDRESSES = {
  1: '0xF6a94dfD0E6ea9ddFdFfE4762Ad4236576136613', // mainnet
  10: '0xb3999F658C0391d94A37f7FF328F3feC942BcADC',
  56: '0x0ACFFB0fb2cddd9BD35d03d359F3D899E32FACc9',
  97: '0x6a4Ed415A06D40ADFC695Cb72C5F446d579dEFBC', // rialto
  137: '0x72550597dc0b2e0beC24e116ADd353599Eff2E35', // polygon
  42161: '0x1F772fA3Bc263160ea09bB16CE1A6B8Fc0Fab36a',
  43114: '0x64D2f9F44FE26C157d552aE7EAa613Ca6587B59e',
  80001: '0x6839F8B4b999eA55B8b1E8FaC31B58d7644264B2', // mumbai
};

export const TRADER_ADDRESSES = {
  1: '0x4B69b7738Dd5354C921e0CACd7B78eC416c8ebfE',
  10: '0x65caF259cC7e2c5309451534d6a6FacBe971b399',
  56: '0x65caF259cC7e2c5309451534d6a6FacBe971b399',
  97: '0xf9d55F554175B8a18cDB167063383f5462442EAD',
  137: '0xfB9b74bfec44155752b867B43B649E2eB36e0b8A',
  42161: '0x65caF259cC7e2c5309451534d6a6FacBe971b399',
  43114: '0x75487f51753d155C709b259B14953996a4Be83a0',
  80001: '0xC2dFA65a1a7106feccd77d0600Da6F3D84F470bF',
};

const HASHFLOW_CROSS_CHAIN_IDS = {
  1: 1, // ethereum mainnet
  56: 6, // binance smart chain
  43114: 4, // avalanche
  137: 5, // polygon
  42161: 2, // arbitrum
  10: 3, // optimism
  97: 104, // bsc testnet
  80001: 103, // mumbai
};

const HASHFLOW_CROSS_CHAIN_RECEIVED_ADDRESSES = {
  1: '0x20F4b902b0b64c4cef75A67A811484B5AAA9cc2e',
  5: '0x89aeF982DE66Fe6df58ed0251E0841CcB2da6E4a',
  10: '0xDC1dF355584Bdaada533d1108eA75d307Db946ee',
  56: '0x7fCb5741c568aD8C2EFcaD03c26c53969Fdad930',
  97: '0x4Bb454811Fd24517380efA1Dd51437d4E75fA385',
  137: '0x5cF689e7d8f2a029A3b65B28c900596292Ce25F8',
  42161: '0x889ddD46451A2D03768CE8eDb4e30b6539089B64',
  43114: '0x9Fee90a9bC7D8dbb8435FE00f1EF906239a8d7B4',
  80001: '0x2B8f14F485616830292482FeC38064578E4EbFa5',
};

export const HASHFLOW_SRC_NETWORK_IDS =
  process.env.REACT_APP_PROCESS_ENV === 'production'
    ? HASHFLOW_NETWORK_IDS
    : HASHFLOW_TEST_NETWORK_IDS;

export const HASHFLOW_DST_NETWORK_IDS =
  process.env.REACT_APP_PROCESS_ENV === 'production'
    ? HASHFLOW_NETWORK_IDS
    : HASHFLOW_TEST_NETWORK_IDS;

// this is mainly providing mumbai eip-1559 gas info,
// which isn't available through the polygonscan-mumbai api
const GAS_STATION_URLS = {
  137: 'https://gasstation-mainnet.matic.network/v2',
  80001: 'https://gasstation-mumbai.matic.today/v2',
};

const RPC_URLS = {
  1: 'https://eth.llamarpc.com',
  10: 'https://endpoints.omniatech.io/v1/op/mainnet/public',
  56: 'https://bsc-mainnet.public.blastapi.io',
  97: 'https://data-seed-prebsc-2-s3.binance.org:8545',
  137: 'https://polygon.llamarpc.com',
  42161: 'https://rpc.ankr.com/arbitrum',
  43114: 'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
  80001: 'https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78',
};

export const validateChainId = (chainId: number) => {
  return HASHFLOW_SRC_NETWORK_IDS.includes(chainId);
};

export const useMarketMakers = (enableQueries: boolean) => {
  const { connectedChainId } = useWallet();
  const client = useTradingApiClient();
  const { run, data } = useAsync();
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    if (!connectedChainId || !enableQueries) {
      return;
    }

    run(
      client('vendor/hashflow/taker/v1/marketMakers', {
        params: {
          networkId: String(connectedChainId),
        },
      }),
    );
  }, [connectedChainId, run, reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    console.log('>>>>> Market maker data:', data);
    if (data?.marketMakers) {
      setResults(data.marketMakers);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [data]);

  const refresh = () => {
    console.log('Refreshing market maker data...');
    setResults(null);
    setLoading(true);
    setReload(new Date());
  };

  return { results: results || [], loading: loading, refresh: refresh };
};

export const usePriceLevels = (marketMakers: any[], enableQueries: boolean) => {
  const { connectedChainId } = useWallet();
  const client = useTradingApiClient();
  const { run, data } = useAsync();
  const [params, setParams] = useState(null);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);

  const buildParams = (makers: any[]) => {
    if (makers?.length) {
      const marketMakerParams = {};
      makers.forEach((mm, i) => {
        marketMakerParams[`marketMakers[${i}]`] = mm;
      });

      setParams(marketMakerParams);
    }
  };

  useEffect(() => {
    buildParams(marketMakers);
  }, [marketMakers]);

  useEffect(() => {
    if (!connectedChainId || !enableQueries) {
      return;
    }

    if (!params || Object.keys(params).length <= 0) {
      return;
    }

    run(
      client('vendor/hashflow/taker/v2/price-levels', {
        params: { networkId: String(connectedChainId), ...params },
      }),
    );
  }, [params, run]);

  useEffect(() => {
    if (!data) {
      return;
    }

    console.log('>>>>> Price level data:', data);
    if (data?.levels) {
      setResults(data.levels);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [data]);

  const refresh = (makers: any[]) => {
    console.log('Refreshing price level data...', makers);
    if (!makers?.length) {
      return;
    }

    setResults(null);
    setLoading(true);
    buildParams(makers);
  };

  return { results: results, loading: loading, refresh: refresh };
};

export const useCrossChainPriceLevels = (
  marketMakers: any[],
  enableQueries: boolean,
) => {
  const { connectedChainId } = useWallet();
  const client = useTradingApiClient();
  const { run, isPending, data } = useAsync();
  const [ht, setHt] = useState({});
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const dstNetworkIds = HASHFLOW_DST_NETWORK_IDS.filter(
      (id) => connectedChainId !== id,
    );

    if (marketMakers.length && dstNetworkIds.length && run && enableQueries) {
      const params = {
        dstNetworkId: undefined,
      };

      marketMakers.forEach((mm, i) => {
        params[`marketMakers[${i}]`] = mm;
      });

      for (let i = 0; i < dstNetworkIds.length; i++) {
        if (dstNetworkIds[i] !== connectedChainId) {
          params.dstNetworkId = String(dstNetworkIds[i]);
          run(
            client('vendor/hashflow/taker/v2/price-levels', {
              params: { networkId: String(connectedChainId), ...params },
            }),
          );
        }
      }
    }
  }, [marketMakers, refetch]);

  useEffect(() => {
    if (data) {
      const newHt = {};
      newHt[data.dstNetworkId] = data.levels;
      setHt({ ...ht, ...newHt });
    }
  }, [data]);

  useEffect(() => {
    if (!isEmptyObject(ht)) {
      console.log('>>>>> Cross-chain price level data:', ht);
    }
  }, [ht]);

  useEffect(() => {
    setHt({});
  }, [connectedChainId]);

  const refresh = () => {
    console.log('Refreshing cross-chain price level data...', marketMakers);
    setRefetch(!refetch);
  };

  return { results: ht, loading: isPending, refresh: refresh };
};

export const useTokenPrices = (symbols: any[], enableQueries: boolean) => {
  const { connectedChainId } = useWallet();
  const client = useTradingApiClient();
  const { run, data } = useAsync();
  const [params, setParams] = useState(null);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const refreshInterval = 60 * 1000;

  const buildParams = (targetSymbols: any[]) => {
    if (targetSymbols?.length) {
      const { symbolKeys } = getSymbolKeys(targetSymbols);
      setParams(symbolKeys);
    }
  };

  useInterval(() => {
    refresh(symbols);
  }, refreshInterval);

  useEffect(() => {
    buildParams(symbols);
  }, [symbols]);

  useEffect(() => {
    if (!connectedChainId || !enableQueries) {
      return;
    }

    if (!params || Object.keys(params).length <= 0) {
      return;
    }

    run(
      client('tokens/prices', {
        params: {
          symbol: params.join(),
        },
      }),
    );
  }, [params, run]);

  useEffect(() => {
    if (!data) {
      return;
    }

    console.log('>>>>> Token USD price data:', data);
    const tokenPrices = data;
    const { symbolKeys, unusualSymbols } = getSymbolKeys(symbols);
    unusualSymbols.forEach((s) => {
      Object.entries(s).map(([k, v]: [string, string]) => {
        tokenPrices[k] = tokenPrices[v];
      });
    });

    setResults(tokenPrices);
    setLoading(false);
  }, [data]);

  const refresh = (symbols: any[]) => {
    console.log('Refreshing token usd price data...', symbols);
    if (!symbols?.length) {
      return;
    }

    setResults(null);
    setLoading(true);
    buildParams(symbols);
  };

  return { results: results, loading: loading, refresh: refresh };
};

export const useTokenLogos = (symbols: any[], enableQueries: boolean) => {
  const { connectedChainId } = useWallet();
  const client = useTradingApiClient();
  const { run, data } = useAsync();
  const [params, setParams] = useState(null);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const refreshInterval = 24 * 60 * 60 * 1000;

  const buildParams = (targetSymbols: any[]) => {
    if (targetSymbols?.length) {
      const { symbolKeys } = getSymbolKeys(targetSymbols);
      setParams(symbolKeys);
    }
  };

  useInterval(() => {
    refresh(symbols);
  }, refreshInterval);

  useEffect(() => {
    buildParams(symbols);
  }, [symbols]);

  useEffect(() => {
    if (!connectedChainId || !enableQueries) {
      return;
    }

    if (!params || Object.keys(params).length <= 0) {
      return;
    }

    run(
      client('tokens/logos', {
        params: {
          symbol: params.join(),
        },
      }),
    );
  }, [params, run]);

  useEffect(() => {
    if (!data) {
      return;
    }

    console.log('>>>>> Token logo data:', data);
    const tokenLogos = data;
    const { symbolKeys, unusualSymbols } = getSymbolKeys(symbols);
    unusualSymbols.forEach((s) => {
      Object.entries(s).map(([k, v]: [string, string]) => {
        tokenLogos[k] = tokenLogos[v];
      });
    });

    setResults(tokenLogos);
    setLoading(false);
  }, [data]);

  const refresh = (symbols: any[]) => {
    console.log('Refreshing token logo data...', symbols);
    if (!symbols?.length) {
      return;
    }

    setResults(null);
    setLoading(true);
    buildParams(symbols);
  };

  return { results: results, loading: loading, refresh: refresh };
};

export const useGasPrices = (enableQueries: boolean) => {
  const { connectedChainId } = useWallet();
  const client = useTradingApiClient();
  const { run, data } = useAsync();
  const [results, setResults] = useState({
    gasPrices: {
      SafeGasPrice: undefined,
      ProposeGasPrice: undefined,
      FastGasPrice: undefined,
      fast: undefined,
      safeLow: undefined,
      standard: undefined,
    },
    disableGas: false,
  });
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);
  const { gasStationPrices } = useGasStation(connectedChainId);
  const refreshInterval = 15 * 1000;

  useInterval(() => {
    refresh();
  }, refreshInterval);

  useEffect(() => {
    if (!connectedChainId || !enableQueries) {
      return;
    }

    run(
      client('gas', {
        params: {
          networkId: String(connectedChainId),
        },
      }),
    );
  }, [connectedChainId, run, reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    let gasPrices = {
      SafeGasPrice: undefined,
      ProposeGasPrice: undefined,
      FastGasPrice: undefined,
      fast: undefined,
      safeLow: undefined,
      standard: undefined,
    };

    let disableGas = false;

    if (data?.result) {
      console.log('>>>>> Gas price data:', data.result);
      if (typeof data.result === 'string') {
        if (!gasStationPrices) {
          disableGas = true;
        }

        const price = new BigNumber(hexToDecimal(data.result))
          .dividedBy(new BigNumber(10).exponentiatedBy(9))
          .toFixed(0);
        gasPrices.SafeGasPrice = price;
        gasPrices.ProposeGasPrice = price;
        gasPrices.FastGasPrice = price;
      } else {
        gasPrices.safeLow = {};
        gasPrices.safeLow.maxPriorityFee = new BigNumber(
          data.result.SafeGasPrice,
        ).toNumber();
        gasPrices.safeLow.maxFee = new BigNumber(data.result.suggestBaseFee)
          .multipliedBy(2)
          .plus(data.result.SafeGasPrice)
          .toNumber();

        gasPrices.standard = {};
        gasPrices.standard.maxPriorityFee = new BigNumber(
          data.result.ProposeGasPrice,
        ).toNumber();
        gasPrices.standard.maxFee = new BigNumber(data.result.suggestBaseFee)
          .multipliedBy(2)
          .plus(data.result.ProposeGasPrice)
          .toNumber();

        gasPrices.fast = {};
        gasPrices.fast.maxPriorityFee = new BigNumber(
          data.result.FastGasPrice,
        ).toNumber();
        gasPrices.fast.maxFee = new BigNumber(data.result.suggestBaseFee)
          .multipliedBy(2)
          .plus(data.result.FastGasPrice)
          .toNumber();
      }
    }

    gasPrices = {
      ...gasPrices,
      fast: gasPrices.fast || gasStationPrices?.fast,
      safeLow: gasPrices.safeLow || gasStationPrices?.safeLow,
      standard: gasPrices.standard || gasStationPrices?.standard,
    };

    setResults({
      gasPrices: gasPrices,
      disableGas: disableGas,
    });
    setLoading(false);
  }, [data]);

  const refresh = () => {
    console.log('Refreshing gas prices...');
    setLoading(true);
    setReload(new Date());
  };

  return { results: results, loading: loading, refresh: refresh };
};

export const useGetAllowance = () => {
  const {
    user: { walletAddress },
  } = useAuth();

  const { connectedChainId } = useWallet();
  const trader = TRADER_ADDRESSES[connectedChainId];

  const [tokenAddress, setTokenAddress] = useState<`0x${string}`>();

  const {
    data: allowance,
    error,
    refetch,
  } = useReadContract({
    abi: Erc20Definition,
    address: tokenAddress,
    functionName: 'allowance',
    args: [walletAddress, trader],
    query: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (
      tokenAddress &&
      tokenAddress !== '0x0000000000000000000000000000000000000000'
    ) {
      refetch();
    }
  }, [tokenAddress]);

  return {
    allowance: allowance?.toString(),
    getAllowance: setTokenAddress,
    refreshAllowance: refetch,
  };
};

export const useHashflowRfq = () => {
  const { connectedChainId } = useWallet();
  const { traderFeeBips } = useTraderFeeBips();

  const [queryData, setQueryData] = useState(null);
  const [recalculateFee, setRecalculateFee] = useState(false);
  const [fee, setFee] = useState('');
  const [quote, setQuote] = useState(null);
  const [quoteExpired, setQuoteExpired] = useState(false);
  const [quoteError, setQuoteError] = useState(null);
  const [baseTokenRefresh, setBaseTokenRefresh] = useState(false);

  useEffect(() => {
    if (connectedChainId) {
      setQueryData(null);
    }
  }, [connectedChainId]);

  const client = useTradingApiClient();
  const result = useQuery({
    enabled: false,
    queryKey: ['hashflowRfq'],
    queryFn: () =>
      client('vendor/hashflow/taker/v2/rfq', {
        method: 'post',
        ...queryData,
      }),
  });

  const { data, error, refetch } = result;

  useEffect(() => {
    if (queryData) {
      refetch();

      const refreshInterval = setInterval(() => {
        refetch();
      }, 10 * 1000);

      return () => clearInterval(refreshInterval);
    }
  }, [queryData]);

  useEffect(() => {
    if (baseTokenRefresh) {
      setQueryData({
        data: {
          ...queryData.data,
          baseTokenAmount: data.quoteData.baseTokenAmount,
          quoteTokenAmount: undefined,
        },
      });
    }
  }, [baseTokenRefresh]);

  useEffect(() => {
    if (data?.quoteData) {
      const {
        quoteData: { baseTokenAmount },
      } = data;

      let baseTokenAmountWithFee = '';

      // if the user provided the quoteTokenAmount, we don't know the fee until
      // we get the baseTokenAmount back from the quote
      if (recalculateFee) {
        BigNumber.config({ ROUNDING_MODE: 1 });
        const recalculatedFee = new BigNumber(baseTokenAmount)
          .multipliedBy(new BigNumber(traderFeeBips))
          .dividedBy(new BigNumber(10000).minus(traderFeeBips))
          .toFixed(0);
        baseTokenAmountWithFee = new BigNumber(baseTokenAmount)
          .plus(recalculatedFee)
          .toFixed(0);
        setFee(recalculatedFee);
      } else {
        baseTokenAmountWithFee = new BigNumber(baseTokenAmount)
          .plus(fee)
          .toFixed(0);
      }
      setQuote({
        ...data,
        baseTokenAmountWithFee,
      });
    } else {
      setQuote(null);
      if (data?.status === 'fail') {
        setQuoteError(data.error);
      }
    }
    setRecalculateFee(false);
  }, [data]);

  const executeRfqQuery = ({ data }) => {
    let { baseTokenAmount } = data;

    // if the user provided the baseTokenAmount, calculate the fee
    if (baseTokenAmount) {
      BigNumber.config({ ROUNDING_MODE: 1 });
      baseTokenAmount = new BigNumber(baseTokenAmount)
        .multipliedBy(new BigNumber(10000).minus(traderFeeBips))
        .dividedBy(10000)
        .toFixed(0);
      const fee = new BigNumber(baseTokenAmount)
        .multipliedBy(new BigNumber(traderFeeBips))
        .dividedBy(new BigNumber(10000).minus(traderFeeBips))
        .toFixed(0);
      setFee(fee);
    } else {
      setRecalculateFee(true);
    }
    setQueryData({ data: { ...data, baseTokenAmount } });
  };

  const resetQuote = () => {
    setQuote(null);
    setQuoteError(null);
    setQueryData(null);
  };

  const stopRefreshingQuote = () => {
    setQueryData(null);
  };

  const forceBaseTokenRefresh = () => setBaseTokenRefresh(true);

  useEffect(() => {
    if (quote?.quoteData.quoteExpiry) {
      const timeout =
        quote?.quoteData.quoteExpiry * 1000 - new Date().getTime();
      if (timeout > 0) {
        const timer = setTimeout(() => {
          setQuoteExpired(true);
        }, timeout);

        return () => clearTimeout(timer);
      }
    }
  }, [quote]);

  useEffect(() => {
    if (quoteExpired) {
      setQuoteExpired(false);
    }
  }, [quoteExpired]);

  return {
    ...result,
    quote,
    fee,
    executeRfqQuery,
    resetQuote,
    quoteExpired,
    quoteError,
    stopRefreshingQuote,
    forceBaseTokenRefresh,
  };
};

export const useConnectedChainTokenBalances = (enableQueries: boolean) => {
  const { connectedChainId } = useWallet();
  const {
    user: { walletAddress },
  } = useAuth();
  const { data: nativeBalance } = useBalance({
    address: walletAddress,
    query: {
      enabled: enableQueries,
    },
  });

  const [contractAddresses, setContractAddresses] = useState([]);
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    console.log('Initializing token data...');
    setContractAddresses([]);
  }, [connectedChainId]);

  const getConnectedChainTokenBalances = (_contractAddresses: string[]) => {
    console.log('Updating token data...');
    setContractAddresses(_contractAddresses);
  };

  let contracts = [];
  const filteredContractAddresses =
    contractAddresses?.filter(
      (cA) => cA !== '0x0000000000000000000000000000000000000000',
    ) || [];

  if (filteredContractAddresses.length) {
    contracts = filteredContractAddresses
      .map((address) => [
        {
          address,
          abi: Erc20Definition,
          functionName: 'balanceOf',
          args: [walletAddress],
        },
      ])
      .flat();
  }

  // @ts-ignore
  const { data, error, isPending, refetch } = useReadContracts({
    contracts,
    query: {
      enabled: false,
    },
  });

  useInterval(() => {
    enableQueries && refetch();
  }, 60 * 1000);

  useEffect(() => {
    if (contractAddresses.length && enableQueries) {
      refetch();
    }
  }, [contractAddresses]);

  useEffect(() => {
    if (data?.length) {
      setBalances(
        data
          .map((d, i) => ({
            balance: d.toString(),
            address: contracts[i].address,
            networkId: connectedChainId,
          }))
          .concat({
            balance: nativeBalance?.value.toString(),
            address: '0x0000000000000000000000000000000000000000',
            networkId: connectedChainId,
          }),
      );
    }
  }, [data]);

  return {
    results: balances,
    getConnectedChainTokenBalances,
  };
};

export const useCrossChainTokenBalances = (enableQueries: boolean) => {
  const {
    user: { walletAddress },
  } = useAuth();

  const [crossChainAddresses, setCrossChainAddresses] = useState([]);
  const [balances, setBalances] = useState([]);

  const getCrossChainTokenBalances = (_crossChainAddresses) => {
    setBalances([]);
    setCrossChainAddresses(_crossChainAddresses);
  };

  const client = useTradingApiClient();
  const balanceQueries = useQueries({
    queries: crossChainAddresses
      .map(({ address, networkId }) => ({
        enabled: false,
        queryKey: ['balance', `${address}-${networkId}`],
        queryFn: () =>
          client('tokens/balance', {
            params: {
              walletAddress,
              tokenAddress: address,
              networkId,
            },
          }),
        staleTime: 60 * 1000,
        onSuccess: (data) => {
          setBalances(
            balances.concat({
              address,
              balance: data,
              networkId: Number(networkId),
            }),
          );
        },
      }))
      .flat(),
  });

  useEffect(() => {
    if (enableQueries && crossChainAddresses.length) {
      balanceQueries.forEach((q) => q.refetch());
    }
  }, [enableQueries, crossChainAddresses]);

  return {
    results: balances,
    getCrossChainTokenBalances,
  };
};

const getSymbolKeys = (symbols: string[]) => {
  const unusualSymbols = [];
  const normalSymbols = new Set();

  symbols.forEach((s) => {
    // handle symbols like USDT.e
    if (s.indexOf('.') > 0) {
      const normalSymbol = s.slice(0, s.indexOf('.'));
      unusualSymbols.push({ [s]: normalSymbol });
      normalSymbols.add(normalSymbol);
    } else {
      normalSymbols.add(s);
    }
  });

  return { symbolKeys: [...normalSymbols], unusualSymbols };
};

export const useDstNetworkOptions = () => {
  const { connectedChainId } = useWallet();

  const [crossChainIds, setCrossChainIds] = useState([]);
  const [dstNetworkIds, setDstNetworkIds] = useState([]);

  const getDstNetworkOptions = (_crossChainIds: number[]) => {
    if (crossChainIds.sort().toString() !== _crossChainIds.sort().toString()) {
      console.log('Updating dst network options...');
      setCrossChainIds(_crossChainIds);
    }
  };

  useEffect(() => {
    const newIds = new Set(crossChainIds).add(connectedChainId);
    setDstNetworkIds([...newIds].sort());
  }, [crossChainIds, connectedChainId]);

  return { dstNetworkIds, getDstNetworkOptions };
};

export const useExecuteCrossChainQuote = () => {
  const { connectedChainId } = useWallet();

  const [dstNetworkId, setDstNetworkId] = useState(0);
  const [quote, setQuote] = useState(null);
  const [gas, setGas] = useState();
  const [crossChainTradeEventData, setCrossChainTradeEventData] =
    useState(null);

  const {
    approvalData,
    approvalReceipt,
    awaitingApproval,
    awaitingApprovalReceipt,
    approvalError,
    approvalReceiptError,
    resetApproval,
  } = useApproval(quote, TRADER_ADDRESSES[connectedChainId]);

  const {
    crossChainTradeData,
    crossChainTradeReceipt,
    awaitingCrossChainTrade,
    awaitingCrossChainTradeReceipt,
    crossChainTradeError,
    crossChainTradeReceiptError,
    resetCrossChainTrade,
    prepareCrossChainTrade,
  } = useTradeXChain(dstNetworkId);

  const executeCrossChainQuote = (
    dstNetworkId: number,
    quote: any,
    gas: any,
  ) => {
    setDstNetworkId(dstNetworkId);
    setQuote(quote);
    setGas(gas);
  };

  const reset = () => {
    setQuote(null);
    resetApproval();
    resetCrossChainTrade();
  };

  useEffect(() => {
    if (approvalError || approvalReceiptError) {
      reset();
    }
  }, [approvalError, approvalReceiptError]);

  const dstProvider = new ethers.providers.JsonRpcProvider(
    RPC_URLS[dstNetworkId],
    dstNetworkId,
  );

  useEffect(() => {
    if (crossChainTradeError || crossChainTradeReceiptError) {
      reset();
    }
  }, [crossChainTradeError, crossChainTradeReceiptError]);

  useEffect(() => {
    if (crossChainTradeData) {
      const hashflowCrossChainReceivedContract = new ethers.Contract(
        HASHFLOW_CROSS_CHAIN_RECEIVED_ADDRESSES[dstNetworkId],
        HashflowCrossChainReceivedDefinition,
        dstProvider,
      );
      dstProvider.on(
        hashflowCrossChainReceivedContract.filters.XChainMessageSuccess(),
        (log) => {
          const iface = new ethers.utils.Interface(
            HashflowCrossChainReceivedDefinition,
          );
          const parsedLog = iface.parseLog(log);
          setCrossChainTradeEventData({ parsedLog, log });
        },
      );
    } else {
      dstProvider.removeAllListeners();
      setCrossChainTradeEventData(null);
    }
  }, [crossChainTradeData]);

  useEffect(() => {
    if (crossChainTradeReceipt) {
      setQuote(null);
    }
  }, [crossChainTradeReceipt]);

  return {
    crossChainTradeData,
    crossChainTradeReceipt,
    crossChainTradeEventData,
    crossChainApprovalData: approvalData,
    crossChainApprovalReceipt: approvalReceipt,
    awaitingCrossChainApproval: awaitingApproval,
    awaitingCrossChainApprovalReceipt: awaitingApprovalReceipt,
    awaitingCrossChainTrade,
    awaitingCrossChainTradeReceipt,
    crossChainTradeError,
    crossChainApprovalError: approvalError,
    crossChainApprovalReceiptError: approvalReceiptError,
    crossChainTradeReceiptError,
    executeCrossChainQuote,
    makeCrossChainTrade: prepareCrossChainTrade,
    reset,
  };
};

export const useExecuteQuote = () => {
  const { connectedChainId } = useWallet();

  const [quote, setQuote] = useState(null);
  const [gas, setGas] = useState();
  const [tradeSingleHopEventData, setTradeSingleHopEventData] = useState(null);

  const {
    approvalData,
    approvalReceipt,
    awaitingApproval,
    awaitingApprovalReceipt,
    approvalError,
    approvalReceiptError,
    resetApproval,
  } = useApproval(quote, TRADER_ADDRESSES[connectedChainId]);

  const {
    tradeSingleHopData,
    tradeSingleHopReceipt,
    awaitingTrade,
    awaitingTradeReceipt,
    tradeSingleHopError,
    tradeSingleHopReceiptError,
    resetTradeSingleHop,
    prepareTradeSingleHop,
  } = useTradeSingleHop();

  const executeQuote = (quote: any, gas: any) => {
    setQuote(quote);
    setGas(gas);
  };

  const reset = () => {
    setQuote(null);
    resetApproval();
    resetTradeSingleHop();
  };

  useEffect(() => {
    if (approvalError || approvalReceiptError) {
      reset();
    }
  }, [approvalError, approvalReceiptError]);

  useEffect(() => {
    if (tradeSingleHopError || tradeSingleHopReceiptError) {
      reset();
    }
  }, [tradeSingleHopError, tradeSingleHopReceiptError]);

  useEffect(() => {
    if (tradeSingleHopReceipt) {
      const iface = new ethers.utils.Interface(TraderDefinition);
      const { logs } = tradeSingleHopReceipt;
      // const log = logs.find(({ topics }) =>
      //   topics.includes(iface.getEventTopic('HashflowTradeSingleHop'))
      // )
      //
      // const parsedLog = iface.parseLog(log)
      const parsedLog = null;
      setTradeSingleHopEventData({
        ...parsedLog,
        transactionHash: tradeSingleHopReceipt.transactionHash,
      });
      setQuote(null);
    }
  }, [tradeSingleHopReceipt]);

  return {
    tradeSingleHopData,
    tradeSingleHopReceipt,
    tradeSingleHopEventData,
    approvalData,
    approvalReceipt,
    awaitingApproval,
    awaitingApprovalReceipt,
    awaitingTrade,
    awaitingTradeReceipt,
    approvalError,
    approvalReceiptError,
    tradeSingleHopError,
    tradeSingleHopReceiptError,
    executeQuote,
    reset,
    makeTrade: prepareTradeSingleHop,
  };
};

export const useApproval = (quote, spender) => {
  const { connectedChainId } = useWallet();

  const isNativeTx =
    quote?.quoteData.baseToken === '0x0000000000000000000000000000000000000000';

  const { data, status, error, refetch } = useSimulateContract();

  useEffect(() => {
    if (!isNativeTx && spender && quote) {
      refetch();
    }
  }, [quote, spender]);

  const {
    data: approvalData,
    error: approvalError,
    isPending: awaitingApproval,
    isSuccess,
    writeContract,
    reset,
  } = useWriteContract();

  const {
    data: approvalReceipt,
    error: approvalReceiptError,
    isLoading: awaitingApprovalReceipt,
  } = useWaitForTransactionReceipt({
    hash: approvalData,
  });

  useEffect(() => {
    if (
      !isNativeTx &&
      writeContract &&
      !approvalData &&
      !awaitingApproval &&
      status === 'success' &&
      !approvalError
    ) {
      writeContract(data);
    }
  }, [writeContract, data, approvalData, awaitingApproval]);

  return {
    approvalData,
    approvalReceipt,
    awaitingApproval,
    awaitingApprovalReceipt,
    approvalError,
    approvalReceiptError,
    getApproval: writeContract,
    resetApproval: reset,
  };
};

const useTradeSingleHop = () => {
  const { connectedChainId } = useWallet();
  const {
    user: { walletAddress },
  } = useAuth();
  const trader = TRADER_ADDRESSES[connectedChainId];

  const [quote, setQuote] = useState(null);
  const [gas, setGas] = useState(null);
  const [enableWrite, setEnableWrite] = useState(false);

  const nativeValue =
    quote?.quoteData.baseToken === '0x0000000000000000000000000000000000000000'
      ? quote.baseTokenAmountWithFee
      : 0;

  const { data, status, error, refetch } = useSimulateContract();

  useEffect(() => {
    if (quote) {
      refetch();
      setEnableWrite(true);
    }
  }, [quote]);

  const {
    data: tradeSingleHopData,
    error: tradeSingleHopError,
    isPending: awaitingTrade,
    writeContract,
    reset,
  } = useWriteContract();

  const {
    data: tradeSingleHopReceipt,
    error: tradeSingleHopReceiptError,
    isLoading: awaitingTradeReceipt,
  } = useWaitForTransactionReceipt({
    hash: tradeSingleHopData,
  });

  useEffect(() => {
    if (
      enableWrite &&
      writeContract &&
      !tradeSingleHopData &&
      !awaitingTrade &&
      status === 'success' &&
      !tradeSingleHopError
    ) {
      writeContract(data);
      setEnableWrite(false);
    }
  }, [writeContract, data, tradeSingleHopData, awaitingTrade]);

  const resetTradeSingleHop = () => {
    reset();
    setEnableWrite(false);
  };

  const prepareTradeSingleHop = (quote, gas) => {
    setGas(gas);
    setQuote(quote);
  };

  return {
    tradeSingleHopData,
    tradeSingleHopReceipt,
    awaitingTrade,
    awaitingTradeReceipt,
    tradeSingleHopError,
    tradeSingleHopReceiptError,
    resetTradeSingleHop,
    prepareTradeSingleHop,
  };
};

const useTradeXChain = (dstNetworkId) => {
  const { connectedChainId } = useWallet();
  const {
    user: { walletAddress },
  } = useAuth();
  const trader = TRADER_ADDRESSES[connectedChainId];

  const [quote, setQuote] = useState(null);
  const [gas, setGas] = useState(null);
  const [enableWrite, setEnableWrite] = useState(false);

  const nativeValue =
    quote?.quoteData.baseToken === '0x0000000000000000000000000000000000000000'
      ? quote.baseTokenAmountWithFee
      : 0;

  const { data, status, error, refetch } = useSimulateContract();

  useEffect(() => {
    if (quote) {
      refetch();
      setEnableWrite(true);
    }
  }, [quote]);

  const {
    data: crossChainTradeData,
    error: crossChainTradeError,
    isPending: awaitingCrossChainTrade,
    writeContract,
    reset,
  } = useWriteContract();

  const {
    data: crossChainTradeReceipt,
    error: crossChainTradeReceiptError,
    isLoading: awaitingCrossChainTradeReceipt,
  } = useWaitForTransactionReceipt({
    hash: crossChainTradeData,
  });

  useEffect(() => {
    if (
      enableWrite &&
      writeContract &&
      !crossChainTradeData &&
      !awaitingCrossChainTrade &&
      status === 'success' &&
      !crossChainTradeError
    ) {
      writeContract(data);
      setEnableWrite(false);
    }
  }, [writeContract, data, crossChainTradeData, awaitingCrossChainTrade]);

  const resetCrossChainTrade = () => {
    reset();
    setEnableWrite(false);
  };

  const prepareCrossChainTrade = (quote, gas) => {
    setGas(gas);
    setQuote(quote);
  };

  return {
    crossChainTradeData,
    crossChainTradeReceipt,
    awaitingCrossChainTrade,
    awaitingCrossChainTradeReceipt,
    crossChainTradeError,
    crossChainTradeReceiptError,
    resetCrossChainTrade,
    prepareCrossChainTrade,
  };
};

const useTraderFeeBips = () => {
  const { connectedChainId } = useWallet();
  const [traderFeeBips, setTraderFeeBips] = useState(null);

  const trader = TRADER_ADDRESSES[connectedChainId];

  const { data, refetch } = useReadContract({
    address: trader,
    abi: TraderDefinition,
    functionName: 'getConfig',
    query: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (trader) {
      refetch();
    }
  }, [trader]);

  useEffect(() => {
    if (data) {
      setTraderFeeBips(data[1].toString());
    }
  }, [data]);

  return {
    traderFeeBips,
  };
};

export const useTraderSplit = () => {
  const { connectedChainId } = useWallet();
  const trader = TRADER_ADDRESSES[connectedChainId];

  const [total, setTotal] = useState('');

  const { data, error, refetch, isSuccess, isLoading } = useReadContract({
    address: trader,
    abi: TraderDefinition,
    functionName: 'getSplit',
    args: [ethers.BigNumber.from(total || 0)],
    query: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (total && trader) {
      refetch();
    }
  }, [total, trader]);

  const getSplit = (total) => {
    setTotal(total);
  };

  // @ts-expect-error
  const { baseTokenAmount, baseTokenFee, baseTokenTotal } = data || {};

  return { baseTokenAmount, baseTokenFee, baseTokenTotal, getSplit };
};

export const useGasStation = (chainId) => {
  const url = GAS_STATION_URLS[chainId];

  const result = useQuery({
    enabled: !!url,
    queryKey: ['gasStation'],
    queryFn: () => axios.get(url),
    refetchInterval: 15 * 1000,
  });

  return { ...result, gasStationPrices: result?.data?.data };
};
