import classNames from 'classnames';
import { ReactNode } from 'react';
import './card-note.css';

interface CardNoteProps {
  icon?: ReactNode;
  text: string;
  className?: any;
}

export const CardNote = ({ icon, text, className }: CardNoteProps) => {
  return (
    <div className={classNames('card-note', className)}>
      {icon && <div className='note-icon'>{icon}</div>}

      <div className='note-text'>{text}</div>
    </div>
  );
};
