import { LoadingIndicator, PageHeader } from '@tokensoft-web/common-ui';
import {
  useConfiguration,
  useGetContracts,
  useToast,
  useWallet,
} from '@tokensoft-web/common-utils';
import React from 'react';
import ContractCard from '../../components/governance/contract-card';
import { isAdvancedDistributorType, isVotingType } from '../../utils/abi';
import './governance.css';

const Governance = () => {
  const [contracts, setContracts] = React.useState(null);
  const { configuration } = useConfiguration();
  const { account } = useWallet();
  const { showErrorToast } = useToast();
  const { mutate: getContracts, isLoading: getContractsLoading } =
    useGetContracts();

  React.useEffect(() => {
    getContractsFunc();
  }, []);

  const getContractsFunc = async () => {
    getContracts(
      {
        projectId: configuration?.project?.id,
        walletAddress: account,
      },
      {
        onSuccess: (response) => {
          setContracts(filterContract(response.contracts));
        },
        onError: (error) => showErrorToast({ description: error.message }),
      },
    );
  };

  const filterContract = (contractData) => {
    let contractsToShow = [];
    let distributorsToShow = [];

    contractData?.forEach((contract, i) => {
      if (isVotingType(contract.interfaces)) {
        if (
          contract.userContractMetadata?.balance &&
          contract.userContractMetadata?.balance !== '0'
        ) {
          if (isAdvancedDistributorType(contract.interfaces)) {
            distributorsToShow.push(contract);
          } else {
            contractsToShow.push(contract);
          }
        }
      }
    });

    return { erc20: contractsToShow, distributors: distributorsToShow };
  };

  return (
    <>
      <PageHeader
        title={`My Voting Power`}
        subtitle={''}
        className={`page-header`}
        breadcrumb='GOVERNANCE'
      />
      <div className='instance-list flex flex-col gap-4'>
        {getContractsLoading ? (
          <div>
            <LoadingIndicator className={'!pt-0'} />
          </div>
        ) : null}

        {!getContractsLoading &&
        contracts?.erc20?.length === 0 &&
        contracts?.distributors?.length === 0 ? (
          <div data-testid='no-records-found' className='flex justify-center'>
            No governance contracts found.
          </div>
        ) : null}

        {contracts?.distributors.length > 0 ? (
          <div data-testid={'distributor-cards-container'} className='mb-14'>
            <div className='flex justify-center font-semibold text-lg mb-9 text-neutral-extra-dark'>
              Distributors
            </div>
            {contracts.distributors.map((contract, i) => (
              <ContractCard
                contract={contract}
                key={i}
                id={`distributor-card-${i}`}
              />
            ))}
          </div>
        ) : null}

        {contracts?.erc20?.length > 0 ? (
          <div data-testid={'erc20-cards-container'} className='mb-14'>
            <div className='flex justify-center font-semibold text-lg mb-9 text-neutral-extra-dark'>
              ERC-20 Tokens
            </div>
            {contracts.erc20.map((contract, i) => (
              <ContractCard
                contract={contract}
                key={i}
                id={`erc20-card-${i}`}
              />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Governance;
