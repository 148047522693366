import { ButtonRow } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { Link } from 'react-router-dom';
import TradingHeader from './trading-header';

const TradingKycRestriction = () => {
  const { pageEvent } = useAnalytics();
  pageEvent('trade', 'tradeProfileIncomplete');

  return (
    <>
      <TradingHeader />

      <div className='mb-12'>
        <div>
          <p className={'text-2xl font-bold my-8'}>
            Oops, your profile is not complete.
          </p>
          <p className='mb-8'>
            Please visit{' '}
            <a className='underline' href={'/account'}>
              My Profile
            </a>{' '}
            or click <b>Verify Now</b> to enter your profile information and/or
            finish the account verification process.
          </p>
        </div>
      </div>

      <ButtonRow place={'start'}>
        <Link className='btn btn-primary' to='/account'>
          Verify Now
        </Link>
      </ButtonRow>
    </>
  );
};

export default TradingKycRestriction;
