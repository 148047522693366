import { Card, RadioInput } from '@tokensoft-web/common-ui';
import { fixAndTrim, useAnalytics } from '@tokensoft-web/common-utils';
import { useState } from 'react';
import { FaGasPump } from 'react-icons/fa';
import { useTrading } from '../../contexts/trading/trading-context';
import '../../pages/trading/trading.css';
import { TRADING_STATE } from '../../utils/enums';

const SelectGas = ({ setDisplayState }) => {
  const { gasPrices, selectedGasKey, setSelectedGasKey } = useTrading();
  const [gasOption, setGasOption] = useState<string>(selectedGasKey);
  const { pageEvent } = useAnalytics();

  return (
    <Card className='w-128 exchange-fuel'>
      <div className='flex items-center flex-grow title-text'>GAS PRICE</div>
      <div className='flex flex-col'>
        <div className='py-4'>
          <>
            <RadioInput
              className={`flex items-center !p-4 mb-2 border border-solid border-neutral-medium rounded gas-radio-button hover:border-neutral-300 high-contrast ${
                gasOption === 'fast' ? 'selected' : ''
              }`}
              key='fast'
              checked={gasOption === 'fast'}
              disabled={false}
              onClick={() => setGasOption('fast')}
            >
              <span className='grow flex items-end'>Fast</span>
              <span>{fixAndTrim(gasPrices.fast.maxPriorityFee, 2)} Gwei</span>
              <FaGasPump className='ml-2' />
            </RadioInput>
            <RadioInput
              className={`flex items-center !p-4 mb-2 border border-solid border-neutral-medium rounded gas-radio-button hover:border-neutral-300 high-contrast ${
                gasOption === 'standard' ? 'selected' : ''
              }`}
              key='standard'
              checked={gasOption === 'standard'}
              disabled={false}
              onClick={() => setGasOption('standard')}
            >
              <span className='grow flex items-end'>Standard</span>
              <span>
                {fixAndTrim(gasPrices.standard.maxPriorityFee, 2)} Gwei
              </span>
              <FaGasPump className='ml-2' />
            </RadioInput>
            <RadioInput
              className={`flex items-center !p-4 mb-2 border border-solid border-neutral-medium rounded gas-radio-button hover:border-neutral-300 high-contrast ${
                gasOption === 'safeLow' ? 'selected' : ''
              }`}
              key='safeLow'
              checked={gasOption === 'safeLow'}
              disabled={false}
              onClick={() => setGasOption('safeLow')}
            >
              <span className='grow flex items-end'>Safe Low</span>
              <span>
                {fixAndTrim(gasPrices.safeLow.maxPriorityFee, 2)} Gwei
              </span>
              <FaGasPump className='ml-2' />
            </RadioInput>
          </>
        </div>
        <div className='flex justify-between'>
          <button
            onClick={() => {
              setDisplayState(TRADING_STATE.MAIN);
            }}
            className='btn btn-dark w-5/12'
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setSelectedGasKey(gasOption);
              setDisplayState(TRADING_STATE.MAIN);
              const price = fixAndTrim(gasPrices[gasOption].maxPriorityFee, 2);
              pageEvent('trade', 'setGasFee', Number(price));
            }}
            className='btn btn-primary w-5/12'
          >
            Save
          </button>
        </div>
      </div>
    </Card>
  );
};

export default SelectGas;
