import { useNetworks, useWagmi, useWallet } from '@tokensoft-web/common-utils';
import { Col } from '../layout/container/col';
import { Row } from '../layout/container/row';
import { PageHeader } from '../layout/page-header/page-header';
import { PageSubtitle } from '../typography/page-subtitle';
import { PageTitle } from '../typography/page-title';
import { NetworkSelect } from './network-select';

export const UnsupportedNetworkWarning = ({
  chainIds,
  title,
  subtitle,
}: {
  chainIds?: number[];
  subtitle?: string;
  title?: string;
}) => {
  const { switchChain } = useWallet();
  const { defaultChain } = useWagmi();
  const { supportedNetworks } = useNetworks();

  const titleText = title || 'Unsupported Network';
  const instructionText =
    subtitle || 'Please connect to a supported network to use this product';

  if (!chainIds || !chainIds.length) {
    return <div></div>;
  }

  const handleNetworkSelect = (chainId) => {
    switchChain(chainId);
  };

  return (
    <>
      <PageHeader>
        <PageTitle>{titleText}</PageTitle>
        <PageSubtitle>{instructionText}</PageSubtitle>
      </PageHeader>

      <Row place={'center'}>
        <Col place={'center'} width={'1/2'}>
          <NetworkSelect
            supportedNetworks={supportedNetworks}
            direction={'col'}
            size={'lg'}
            selected={defaultChain?.id}
            setSelected={handleNetworkSelect}
          />
        </Col>
      </Row>
    </>
  );
};
