import { UnsupportedNetworkWarning } from '@tokensoft-web/common-ui';
import {
  FEATURE,
  FEATURE_RESTRICTION,
  SKIP_TRADING_INTRO_SESSION_KEY,
  useFeature,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useState } from 'react';
import GlobalRestriction from '../../components/feature/global-restriction';
import WhitelabelRestriction from '../../components/feature/whitelabel-restriction';
import TradingApp from '../../components/trading/trading-app';
import TradingIntro from '../../components/trading/trading-intro';
import TradingKycRestriction from '../../components/trading/trading-kyc-restriction';
import TradingRegionRestriction from '../../components/trading/trading-region-restriction';
import TradingWalletRestriction from '../../components/trading/trading-wallet-restriction';
import { TradingProvider } from '../../contexts/trading/trading-provider';
import {
  HASHFLOW_SRC_NETWORK_IDS,
  validateChainId,
} from '../../services/hashflow-service';
import './trading.css';

const TradingPage = () => {
  const { getFeatureRestriction } = useFeature();
  const { connectedChainId } = useWallet();
  const [skipIntroPage, setSkipIntroPage] = useState(
    sessionStorage.getItem(SKIP_TRADING_INTRO_SESSION_KEY) === 'true',
  );
  let restriction = getFeatureRestriction(FEATURE.TRADING);

  if (!restriction) {
    if (!validateChainId(Number(connectedChainId))) {
      return (
        <UnsupportedNetworkWarning
          chainIds={HASHFLOW_SRC_NETWORK_IDS}
          subtitle={`Please connect to a supported network to use Tokensoft Trading`}
        />
      );
    }

    if (skipIntroPage) {
      return (
        <TradingProvider>
          <TradingApp />
        </TradingProvider>
      );
    } else {
      return (
        <TradingIntro
          onContinue={() => {
            sessionStorage.setItem(SKIP_TRADING_INTRO_SESSION_KEY, 'true');
            setSkipIntroPage(true);
          }}
        />
      );
    }
  }

  switch (restriction) {
    case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
      return <GlobalRestriction />;
    case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
      return <WhitelabelRestriction />;
    case FEATURE_RESTRICTION.KYC_RESTRICTION:
      return <TradingKycRestriction />;
    case FEATURE_RESTRICTION.REGION_RESTRICTION:
      return <TradingRegionRestriction />;
    default:
      return <TradingWalletRestriction />;
  }
};

export default TradingPage;
