import { useWallet } from '@tokensoft-web/common-utils';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useGetExchangeData } from '../../services/exchange-service';
import { TradingContext } from './trading-context';

interface TradingProviderProps {
  children?: ReactNode;
}

export const TradingProvider: FC<TradingProviderProps> = ({ children }) => {
  const { connectedChainId } = useWallet();
  const {
    priceLevels,
    baseToken,
    setBaseToken,
    baseTokenList,
    baseTokenAmount,
    setBaseTokenAmount,
    singleChainPairs,
    dstNetworkIds,
    getDstNetworkOptions,
    crossChainPairs,
    gasPrices,
    disableGas,
    dstNetworkId,
    setDstNetworkId,
    refresh,
    crossChainPriceLevels,
    tokenInfo,
    crossChainBaseTokenList,
  } = useGetExchangeData();

  const [quoteToken, setQuoteToken] = useState('');
  const [quoteTokenAmount, setQuoteTokenAmount] = useState<string>('');
  const [tokenList, setTokenList] = useState<string[]>([]);
  const [selectedGasKey, setSelectedGasKey] = useState<string>();

  useEffect(() => {
    setSelectedGasKey(disableGas ? 'ProposeGasPrice' : 'standard');
  }, [disableGas]);

  return (
    <TradingContext.Provider
      value={{
        priceLevels,
        singleChainPairs,
        baseToken,
        setBaseToken,
        baseTokenAmount,
        setBaseTokenAmount,
        baseTokenList,
        crossChainBaseTokenList,
        gasPrices,
        disableGas,
        selectedGas: gasPrices[selectedGasKey],
        selectedGasKey,
        setSelectedGasKey,
        dstNetworkIds,
        getDstNetworkOptions,
        refresh,
        crossChainPriceLevels,
        dstNetworkId,
        setDstNetworkId,
        crossChainPairs,
        tokenInfo,
        quoteToken,
        setQuoteToken,
        quoteTokenAmount,
        setQuoteTokenAmount,
        tokenList,
        setTokenList,
        crossChainTrade: dstNetworkId && connectedChainId !== dstNetworkId,
      }}
    >
      {children}
    </TradingContext.Provider>
  );
};
