import {
  FEATURE,
  getYear,
  useAccount,
  useFeature,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { AiFillBank, AiOutlineGlobal } from 'react-icons/ai';
import { FaChartPie, FaUserAlt } from 'react-icons/fa';
import { GiToken } from 'react-icons/gi';
import { useLocation } from 'react-router-dom';
import { TradingIcon } from '../../icon/trading-icon';
import { RouteLink } from '../../route-link/route-link';
import './sidebar.css';

export const Sidebar = () => {
  const { getFeatureRestriction, isFeatureEnabled } = useFeature();
  const { pathname } = useLocation();
  const { account } = useAccount();

  const tradingRestriction = getFeatureRestriction(FEATURE.TRADING);
  const claimsRestriction = getFeatureRestriction(FEATURE.CLAIMS);
  const adminRestriction = getFeatureRestriction(FEATURE.ADMIN);
  const hideGovernance = isFeatureEnabled(FEATURE.HIDE_GOVERNANCE);

  const atIndexRoute =
    pathname === '' || pathname === '/' || pathname === '/dashboard';

  return (
    <div className='sidebar deznav' data-testid={'sidebar'}>
      <div className='deznav-scroll flex flex-col justify-between'>
        <ul className='metismenu' id='menu' role={'list'}>
          <li
            role={'listitem'}
            className={classNames(atIndexRoute ? 'mm-active pt-4' : 'pt-4')}
          >
            <RouteLink url={'/dashboard'}>
              <>
                <FaChartPie />
                <span className='nav-text'>Dashboard</span>
              </>
            </RouteLink>
          </li>

          <li
            role={'listitem'}
            className={classNames(
              pathname.startsWith('/trade') ? 'mm-active' : '',
              tradingRestriction ? `${tradingRestriction}` : '',
            )}
          >
            <RouteLink url={'/trade'}>
              <>
                <TradingIcon className={'small'} />
                <span className='nav-text'>Trade</span>
              </>
            </RouteLink>
          </li>

          <li
            role={'listitem'}
            className={classNames(
              pathname.startsWith('/claim') ? 'mm-active' : '',
              claimsRestriction ? `${claimsRestriction}` : '',
            )}
          >
            <RouteLink url={'/claim'}>
              <>
                <GiToken />
                <span className='nav-text'>Claims</span>
              </>
            </RouteLink>
          </li>

          {!hideGovernance ? (
            <li
              role={'listitem'}
              className={classNames(
                pathname.startsWith('/governance') ? 'mm-active' : '',
              )}
            >
              <RouteLink url={'/governance'}>
                <>
                  <AiFillBank />
                  <span className='nav-text'>Governance</span>
                </>
              </RouteLink>
            </li>
          ) : null}

          <li
            role={'listitem'}
            className={classNames(
              pathname.startsWith('/account') ? 'mm-active' : '',
            )}
          >
            <RouteLink url={'/account'}>
              <>
                <FaUserAlt />
                <span className='nav-text'>My Profile</span>
              </>
            </RouteLink>
          </li>

          {account?.projects?.length > 1 && (
            <li
              role={'listitem'}
              className={classNames(
                pathname.startsWith('/admin') ? 'mm-active' : '',
                adminRestriction ? `${adminRestriction}` : '',
              )}
            >
              <RouteLink url={'/admin'}>
                <>
                  <AiOutlineGlobal />
                  <span className='nav-text'>Admin</span>
                </>
              </RouteLink>
            </li>
          )}

          {account?.projects?.length === 1 && (
            <li
              role={'listitem'}
              className={classNames(
                pathname.startsWith('/admin') ? 'mm-active' : '',
                adminRestriction ? `${adminRestriction}` : '',
              )}
            >
              <RouteLink url={'/admin/project/' + account.projects[0].id}>
                <>
                  <AiOutlineGlobal />
                  <span className='nav-text'>Admin</span>
                </>
              </RouteLink>
            </li>
          )}
        </ul>
        <div className='copyright text-center'>
          {pathname.startsWith('/admin') ? (
            <a
              href='https://tokensoft.io/terms'
              target='_blank'
              className='sidebar-font-color'
            >
              Terms of Service
            </a>
          ) : null}
          <p className='sidebar-font-color'>© {getYear()} Tokensoft Inc.</p>
        </div>
      </div>
    </div>
  );
};
