export const DelegateIcon = () => {
  return (
    <svg
      width='23'
      height='20'
      viewBox='0 0 23 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.33234 14.0021C9.33234 12.1375 8.23287 10.5252 6.64834 9.77852C7.07265 9.30635 7.33134 8.68247 7.33134 7.9992C7.33134 6.52959 6.13574 5.33398 4.66617 5.33398C3.19655 5.33398 2.00095 6.52959 2.00095 7.9992C2.00095 8.68251 2.25964 9.30635 2.68399 9.77857C1.09947 10.5252 0 12.1375 0 14.0021V14.6663H9.33234V14.0021ZM3.32944 7.9992C3.32944 7.26209 3.9291 6.66243 4.66621 6.66243C5.40328 6.66243 6.00294 7.26209 6.00294 7.9992C6.00294 8.73631 5.40328 9.33598 4.66621 9.33598C3.92906 9.33593 3.32944 8.73627 3.32944 7.9992ZM1.39482 13.3379C1.70364 11.8145 3.05308 10.6644 4.66617 10.6644C6.27926 10.6644 7.62869 11.8145 7.93751 13.3379H1.39482Z'
        fill='currentColor'
      />
      <path
        d='M19.9882 9.77852C20.4125 9.30635 20.6712 8.68247 20.6712 7.9992C20.6712 6.52959 19.4756 5.33398 18.006 5.33398C16.5364 5.33398 15.3408 6.52959 15.3408 7.9992C15.3408 8.68251 15.5995 9.30635 16.0238 9.77857C14.4393 10.5252 13.3398 12.1376 13.3398 14.0021V14.6664H22.6722V14.0021C22.6722 12.1375 21.5727 10.5252 19.9882 9.77852ZM16.6693 7.9992C16.6693 7.26209 17.2689 6.66243 18.0061 6.66243C18.7431 6.66243 19.3428 7.26209 19.3428 7.9992C19.3428 8.73631 18.7431 9.33597 18.0061 9.33597C17.2689 9.33593 16.6693 8.73627 16.6693 7.9992ZM14.7347 13.3379C15.0435 11.8145 16.3929 10.6644 18.006 10.6644C19.6191 10.6644 20.9685 11.8145 21.2774 13.3379H14.7347Z'
        fill='currentColor'
      />
      <path
        d='M7.47764 3.23063L6.21752 2.99682C9.60834 0.515325 14.4078 0.805148 17.4693 3.86664L18.4087 2.9273C14.9492 -0.558193 9.53784 -0.893581 5.71984 1.72076L5.87305 0.894951L4.56688 0.652598L3.95898 3.92886L7.23524 4.53676L7.47764 3.23063Z'
        fill='currentColor'
      />
      <path
        d='M15.1947 16.769L16.4548 17.0028C13.064 19.4843 8.26456 19.1945 5.20302 16.133L4.26367 17.0723C7.72312 20.5579 13.1345 20.8932 16.9525 18.2789L16.7993 19.1047L18.1055 19.347L18.7134 16.0708L15.4371 15.4629L15.1947 16.769Z'
        fill='currentColor'
      />
    </svg>
  );
};
