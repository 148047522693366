import { Card, InputGroup, TradingIcon } from '@tokensoft-web/common-ui';
import {
  add,
  convertBaseUnitsToDecimal,
  div,
  fixAndTrim,
  gt,
  isEmptyObject,
  lt,
  mult,
  ne,
  toBaseUnits,
  useAuth,
  useWallet,
} from '@tokensoft-web/common-utils';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaChevronDown, FaGasPump } from 'react-icons/fa';
import { useBalance } from 'wagmi';
import NetworkDropdown from '../../components/trading/network-dropdown';
import { useTrading } from '../../contexts/trading/trading-context';
import '../../pages/trading/trading.css';
import {
  HASHFLOW_SRC_NETWORK_IDS,
  validateChainId,
} from '../../services/hashflow-service';
import { TRADING_STATE } from '../../utils/enums';

const TradingMain = ({
  setDisplayState,
  resetQuote,
  quote,
  quoteLoading,
  quoteError,
  onMainButtonClick,
  executeRfqQuery,
}) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { connectedChainId, switchChain } = useWallet();
  const { data: nativeBalanceInfo } = useBalance({
    address: walletAddress,
    query: {
      enabled: validateChainId(connectedChainId),
    },
  });

  const {
    priceLevels,
    dstNetworkId,
    setDstNetworkId,
    baseToken,
    setBaseToken,
    baseTokenAmount,
    setBaseTokenAmount,
    dstNetworkIds,
    gasPrices,
    selectedGas,
    disableGas,
    crossChainBaseTokenList,
    baseTokenList,
    crossChainPairs,
    singleChainPairs,
    tokenInfo,
    quoteToken,
    setQuoteToken,
    quoteTokenAmount,
    setQuoteTokenAmount,
    setTokenList,
    crossChainTrade,
  } = useTrading();

  const [tokenAmountUpdated, setTokenAmountUpdated] = useState('');
  const [tokenInterchange, setTokenInterchange] = useState(false);
  const [errorQuoteText, setErrorQuoteText] = useState([]);

  const baseTokenInfo = tokenInfo[connectedChainId];
  const quoteTokenInfo =
    tokenInfo[crossChainTrade ? dstNetworkId : connectedChainId];
  const bt = baseTokenInfo?.[baseToken];
  const qt = quoteTokenInfo?.[quoteToken];
  const disableTradeButton =
    errorQuoteText.length > 0 ||
    quoteLoading ||
    !quote ||
    (bt && lt(baseTokenInfo[baseToken].balance, baseTokenAmount));

  const showTransactionPreInfo =
    quote?.status == 'success' &&
    baseTokenAmount &&
    ne(baseTokenAmount, 0) &&
    quoteTokenAmount &&
    ne(quoteTokenAmount, 0);

  const baseTokenError = bt && lt(bt.balance, baseTokenAmount);

  const showGasEstimatedText = () => {
    let gasValue = mult(
      quote.gasEstimate,
      mult(
        quote.nativeTokenPriceUsd,
        div(disableGas ? selectedGas : selectedGas.maxFee, 1000000000),
      ),
      2,
    );
    if (gasValue < 0.01) {
      return '< $0.01';
    } else {
      return `$${gasValue}`;
    }
  };

  const handleSrcNetworkSelect = ({ value: networkId }) => {
    switchChain(networkId);
    // setDstNetworkId(networkId)
    setBaseTokenAmount('');
    setQuoteTokenAmount('');
    resetQuote();
  };

  const handleDstNetworkSelect = ({ value: networkId }) => {
    setDstNetworkId(networkId);
    setBaseTokenAmount('');
    setQuoteTokenAmount('');
    resetQuote();
  };

  const showSelectBaseToken = () => {
    setDisplayState(TRADING_STATE.SELECT_BASE_TOKEN);
    setTokenList(crossChainTrade ? crossChainBaseTokenList : baseTokenList);
  };

  const showSelectQuoteToken = () => {
    setDisplayState(TRADING_STATE.SELECT_QUOTE_TOKEN);
    setTokenList(
      crossChainTrade
        ? [...crossChainPairs[dstNetworkId][baseToken]]
        : singleChainPairs[baseToken],
    );
  };

  const handleBaseAmountChange = (value, event) => {
    if (event?.target) {
      setTokenAmountUpdated('baseToken');
    }
    setBaseTokenAmount(value);
  };

  const handleSelectMaxClick = () => {
    setTokenAmountUpdated('baseToken');
    setBaseTokenAmount(bt.balance);
  };

  const handleQuoteAmountChange = (value, event) => {
    if (event?.target) {
      setTokenAmountUpdated('quoteToken');
    }
    setQuoteTokenAmount(value);
  };

  const handleInterchangeTokens = () => {
    resetQuote();
    setTokenInterchange(true);
    setQuoteToken(baseToken);
    setQuoteTokenAmount('');
    setBaseTokenAmount(quoteTokenAmount);
    setBaseToken(quoteToken);
  };

  const executeRfqQueryFn = (
    baseToken,
    value,
    baseTokenInfo,
    quoteTokenInfo,
  ) => {
    if (
      (baseToken && !baseTokenInfo?.decimals) ||
      (!baseToken && !quoteTokenInfo?.decimals) ||
      value === '0'
    )
      return;

    let data = {
      networkId: connectedChainId,
      ...(crossChainTrade && { dstNetworkId: Number(dstNetworkId) }),
      baseToken: baseTokenInfo.address,
      quoteToken: quoteTokenInfo.address,
      trader: walletAddress,
    };

    if (baseToken) {
      data['baseTokenAmount'] = toBaseUnits(value, baseTokenInfo.decimals);
    } else {
      data['quoteTokenAmount'] = toBaseUnits(value, quoteTokenInfo.decimals);
    }

    executeRfqQuery({ data });
  };

  useEffect(() => {
    if (quote) {
      if (quote.status == 'success') {
        setErrorQuoteText([]);
        setBaseTokenAmount(
          convertBaseUnitsToDecimal(quote.baseTokenAmountWithFee, bt.decimals),
        );
        setQuoteTokenAmount(
          convertBaseUnitsToDecimal(
            quote.quoteData.quoteTokenAmount,
            qt.decimals,
          ),
        );
      }

      if (crossChainTrade) {
        const nativeBalance = nativeBalanceInfo.value.toString();
        const gasCost = mult(
          mult(
            mult(quote.gasEstimate, 1.75),
            selectedGas?.maxFee || selectedGas,
          ),
          1000000000,
        );
        const txCost = add(gasCost, quote.xChainFeeEstimate);
        const insufficientFunds = lt(nativeBalance, txCost);
        if (insufficientFunds) {
          const formattedTxCost = convertBaseUnitsToDecimal(
            txCost,
            nativeBalanceInfo.decimals,
            6,
          );
          setErrorQuoteText([
            `You need approximately ${formattedTxCost} ${nativeBalanceInfo.symbol}`,
            'to cover the cross chain fee and gas',
          ]);
        }
      }
    }
  }, [quote]);

  useEffect(() => {
    if (quoteError) {
      if (quoteError.code === 76) {
        // exceeds supported amount
        setErrorQuoteText([
          `Tokensoft didn't receive a quote from market makers because there's not enough liquidity available.`,
          ` Try choosing a different amount.`,
        ]);
      } else if (quoteError.code === 79) {
        setErrorQuoteText([
          `${baseToken} amount is below the minimum the market makers are offering.`,
          'Try choosing a larger amount, or another trading pair.',
        ]);
      } else {
        setErrorQuoteText([
          `Something went wrong while fetching your quote.`,
          `Please refresh the page.`,
        ]);
      }
    }
  }, [quoteError]);

  useEffect(() => {
    if (
      baseTokenAmount &&
      priceLevels &&
      tokenAmountUpdated == 'baseToken' &&
      !tokenInterchange
    ) {
      resetQuote();
      const delayDebounceFn = setTimeout(() => {
        setTokenAmountUpdated('');
        setQuoteTokenAmount('');
        executeRfqQueryFn(true, baseTokenAmount, bt, qt);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [baseTokenAmount]);

  useEffect(() => {
    if (
      quoteTokenAmount &&
      priceLevels &&
      tokenAmountUpdated == 'quoteToken' &&
      !tokenInterchange
    ) {
      resetQuote();
      const delayDebounceFn = setTimeout(() => {
        setTokenAmountUpdated('');
        setBaseTokenAmount('');

        executeRfqQueryFn(false, quoteTokenAmount, bt, qt);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [quoteTokenAmount]);

  useEffect(() => {
    if (tokenInterchange) {
      if (gt(baseTokenAmount, 0)) {
        setQuoteTokenAmount('');
        executeRfqQueryFn(true, baseTokenAmount, bt, qt);
      } else {
        if (gt(quoteTokenAmount, 0)) {
          setBaseTokenAmount('');
          executeRfqQueryFn(false, quoteTokenAmount, bt, qt);
        }
      }
      setTokenInterchange(false);
    }
  }, [tokenInterchange]);

  return (
    <>
      <Card className='exchange-main'>
        <div className='flex'>
          <div className='flex min-w-28'>
            <button
              className='btn btn-outline-light !p-2 !flex justify-center items-center w-full disabled:pointer-events-none'
              onClick={() => setDisplayState(TRADING_STATE.GAS)}
              disabled={disableGas}
            >
              <span className='mr-2 !text-xs'>
                {fixAndTrim(
                  disableGas ? selectedGas : selectedGas?.maxPriorityFee,
                  2,
                ) || '--'}{' '}
                Gwei
              </span>{' '}
              <FaGasPump />
            </button>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='flex flex-col my-6'>
            <div className='flex justify-between items-center'>
              <div className='text-sm'>You Sell</div>
              <NetworkDropdown
                networkOptionIds={HASHFLOW_SRC_NETWORK_IDS}
                selectedNetworkId={connectedChainId}
                onNetworkChange={handleSrcNetworkSelect}
              />
            </div>
            <Card
              className={classNames(
                'data-card negative-card small-padding',
                baseTokenError ? 'border-danger-medium' : null,
              )}
            >
              {baseTokenError ? (
                <span className='insuficient-balance text-xs text-danger-medium'>
                  Insufficient Balance
                </span>
              ) : null}
              <div className='flex text-3xl high-contrast mb-2'>
                <button
                  className='flex items-center p-2 rounded hover:bg-neutral-light hover:cursor-pointer'
                  onClick={showSelectBaseToken}
                  disabled={!bt}
                >
                  <div className='mr-3 w-9'>
                    {bt?.logo ? (
                      <img
                        className='rounded-full'
                        src={`${bt.logo}`}
                        width='48'
                        height='48'
                      />
                    ) : (
                      <div className='no-logo-token bg-neutral-medium rounded-full flex items-center justify-center text-lg'>
                        {baseToken?.charAt(0)}
                      </div>
                    )}
                  </div>
                  <div className='mr-2'>{baseToken}</div>
                  <FaChevronDown size={20} className='w-5' />
                </button>
                <InputGroup
                  type='number'
                  name='baseTokenAmount'
                  placeholder='0'
                  allowNegative={false}
                  value={baseTokenAmount}
                  decimalScale={bt?.decimals || 0}
                  required={false}
                  onChange={({ value }, { event }) =>
                    handleBaseAmountChange(value, event)
                  }
                  readOnly={!bt}
                />
              </div>
              <div className='flex justify-between text-xs text-neutral-medium'>
                <div className='pl-2'>
                  Balance: {bt ? fixAndTrim(bt.balance, 6) : '--'}
                </div>
                <div>
                  <button
                    className='text-primary-light'
                    onClick={handleSelectMaxClick}
                    disabled={!bt}
                  >
                    Select Max
                  </button>
                </div>
              </div>
            </Card>
          </div>
          <div className='flex justify-center items-center mt-4'>
            <button
              onClick={handleInterchangeTokens}
              disabled={crossChainTrade}
              className='relative'
            >
              <TradingIcon className={'large fill-info-medium'}></TradingIcon>
            </button>
          </div>
          <div className='flex flex-col mt-4 mb-6'>
            <div className='flex justify-between items-center mb-2'>
              <div className='text-sm'>You Buy</div>
              <NetworkDropdown
                networkOptionIds={dstNetworkIds}
                selectedNetworkId={dstNetworkId}
                onNetworkChange={handleDstNetworkSelect}
              />
            </div>
            <Card className='data-card negative-card small-padding'>
              <div className='flex text-3xl high-contrast mb-2'>
                <button
                  className='flex items-center p-2 rounded hover:bg-neutral-light hover: cursor-pointer'
                  onClick={showSelectQuoteToken}
                  disabled={isEmptyObject(quoteTokenInfo)}
                >
                  <div className='mr-3 w-9'>
                    {qt?.logo ? (
                      <img
                        className='rounded-full'
                        src={`${qt.logo}`}
                        width='48'
                        height='48'
                      />
                    ) : (
                      <div className='no-logo-token bg-neutral-medium rounded-full flex items-center justify-center text-lg'>
                        {quoteToken?.charAt(0)}
                      </div>
                    )}
                  </div>
                  <div className='mr-2'>{quoteToken}</div>
                  <FaChevronDown size={20} className='w-5' />
                </button>
                <InputGroup
                  type='number'
                  name='quoteTokenAmount'
                  placeholder='0'
                  allowNegative={false}
                  value={quoteTokenAmount}
                  decimalScale={qt?.decimals || 0}
                  required={false}
                  onChange={({ value }, { event }) =>
                    handleQuoteAmountChange(value, event)
                  }
                  readOnly={!qt}
                />
              </div>
              <div className='flex text-xs text-neutral-medium'>
                {
                  <div className='pl-2'>
                    Balance: {qt ? fixAndTrim(qt.balance, 6) : '--'}
                  </div>
                }
              </div>
            </Card>
          </div>
          {errorQuoteText.length ? (
            <div className='flex flex-col high-contrast mb-4 h-20 justify-center items-center rounded-sm border border-danger-medium border-solid text-xs px-5'>
              {errorQuoteText.map((text) => (
                <span className='text-center'>{text}</span>
              ))}
            </div>
          ) : (
            <div className='flex flex-col high-contrast mb-4 h-20 justify-around'>
              <div className='flex justify-between text-sm'>
                <div>Rate</div>
                <div className='text-neutral-medium flex justify-end flex-grow'>
                  {showTransactionPreInfo
                    ? `1 ${baseToken} =  ${div(
                        quoteTokenAmount,
                        baseTokenAmount,
                        6,
                      )} ${quoteToken}`
                    : '--'}
                </div>
              </div>
              <div className='flex justify-between text-sm'>
                <div>Gas Estimate</div>
                <div className='text-neutral-medium flex justify-end flex-grow'>
                  {showTransactionPreInfo ? showGasEstimatedText() : '--'}
                </div>
              </div>
            </div>
          )}
          <button
            className='btn btn-primary'
            onClick={onMainButtonClick}
            disabled={disableTradeButton}
          >
            {quoteLoading ? (
              <div className='flex w-full items-center justify-center'>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={24} />
                </div>
                <span className='pl-2'>Fetching Quote...</span>
              </div>
            ) : (
              'Trade'
            )}
          </button>
        </div>
      </Card>
    </>
  );
};

export default TradingMain;
