import {
  convertBaseUnitsToDecimal,
  isEmptyObject,
  mult,
  useConfiguration,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import {
  useConnectedChainTokenBalances,
  useCrossChainPriceLevels,
  useCrossChainTokenBalances,
  useDstNetworkOptions,
  useGasPrices,
  useMarketMakers,
  usePriceLevels,
  useTokenLogos,
  useTokenPrices,
  validateChainId,
} from './hashflow-service';

export const useGetExchangeData = () => {
  const { connectedChainId } = useWallet();
  const enableQueries = validateChainId(connectedChainId);

  const [connectedChainAddresses, setConnectedChainAddresses] = useState([]);
  const [crossChainAddresses, setCrossChainAddresses] = useState([]);

  const [singleChainPairs, setSingleChainPairs] = useState<object>();
  const [crossChainPairs, setCrossChainPairs] = useState({});

  const [baseToken, setBaseToken] = useState('');
  const [baseTokenAmount, setBaseTokenAmount] = useState<string>('');

  const [baseTokenList, setBaseTokenList] = useState<string[]>([]);
  const [crossChainBaseTokenList, setCrossChainBaseTokenList] = useState([]);

  const [crossChainIds, setCrossChainIds] = useState([]);
  const [dstNetworkId, setDstNetworkId] = useState(connectedChainId);

  const [symbols, setSymbols] = useState([]);
  const { configuration } = useConfiguration();
  useEffect(() => {
    if (configuration?.trading?.symbolList) {
      setSymbols(configuration.trading.symbolList);
    }
  }, [configuration]);

  const { results: marketMakers, refresh: refreshMarketMakers } =
    useMarketMakers(enableQueries);
  const { results: priceLevels } = usePriceLevels(marketMakers, enableQueries);
  const { results: crossChainPriceLevels } = useCrossChainPriceLevels(
    marketMakers,
    enableQueries,
  );
  const { results: tokenPrices } = useTokenPrices(symbols, enableQueries);
  const { results: tokenLogos } = useTokenLogos(symbols, enableQueries);
  const {
    results: { gasPrices, disableGas },
  } = useGasPrices(enableQueries);
  const {
    results: connectedChainTokenBalances,
    getConnectedChainTokenBalances,
  } = useConnectedChainTokenBalances(enableQueries);
  const { dstNetworkIds, getDstNetworkOptions } = useDstNetworkOptions();
  const { results: crossChainTokenBalances, getCrossChainTokenBalances } =
    useCrossChainTokenBalances(enableQueries);

  /**
   * Reload exchange data
   */
  const refresh = () => {
    refreshMarketMakers();
  };

  useEffect(() => {
    if (connectedChainId) {
      setDstNetworkId(connectedChainId);
    }
  }, [connectedChainId]);

  useEffect(() => {
    if (dstNetworkId && crossChainPairs[dstNetworkId]) {
      setCrossChainBaseTokenList(Object.keys(crossChainPairs[dstNetworkId]));
    }
  }, [dstNetworkId]);

  /**
   * Process price levels in response to new price level data
   */
  useEffect(() => {
    if (priceLevels) {
      console.log('Updating pricing levels...', { marketMakers, priceLevels });
      let _erc20Addresses = new Set<string>();
      let _baseToken = new Set<string>();

      let tokensRelation = {};
      marketMakers?.forEach((mm) => {
        priceLevels[mm]?.forEach((level) => {
          _erc20Addresses.add(level.pair.baseToken);
          _erc20Addresses.add(level.pair.quoteToken);
          _baseToken.add(level.pair.baseTokenName);

          if (tokensRelation[level.pair.baseTokenName]) {
            if (
              !tokensRelation[level.pair.baseTokenName].includes(
                level.pair.quoteTokenName,
              )
            ) {
              tokensRelation[level.pair.baseTokenName].push(
                level.pair.quoteTokenName,
              );
            }
          } else {
            tokensRelation[level.pair.baseTokenName] = [
              level.pair.quoteTokenName,
            ];
          }
        });
      });

      const baseTokenArray = [..._baseToken];
      setBaseTokenList(baseTokenArray);
      if (baseToken) {
        if (!baseTokenArray.includes(baseToken)) {
          setBaseToken(baseTokenArray[0]);
        }
      } else {
        setBaseToken(baseTokenArray[0]);
      }
      setSingleChainPairs(tokensRelation);
      setConnectedChainAddresses([..._erc20Addresses]);
    } else {
      setConnectedChainAddresses([]);
    }
  }, [priceLevels]);

  useEffect(() => {
    if (connectedChainAddresses.length) {
      getConnectedChainTokenBalances(connectedChainAddresses);
    }
  }, [connectedChainAddresses, connectedChainId]);

  useEffect(() => {
    if (!isEmptyObject(crossChainPriceLevels)) {
      console.log('Updating cross-chain pricing levels...', {
        marketMakers,
        crossChainPriceLevels,
      });

      const pairs = {};
      let _crossChainIds = new Set<number>();
      let _baseToken = new Set<string>();
      let _crossChainAddresses = [];

      Object.entries(crossChainPriceLevels).forEach(
        ([dstNetworkId, levels]) => {
          let tokenAddresses = new Set<string>();

          Object.entries(levels).forEach(([mm, data]) => {
            if (data.length) {
              if (!pairs[dstNetworkId]) {
                pairs[dstNetworkId] = {};
              }
              _crossChainIds.add(Number(dstNetworkId));

              data.forEach(({ pair }) => {
                _baseToken.add(pair.baseTokenName);
                tokenAddresses.add(pair.quoteToken);

                if (pairs[dstNetworkId][pair.baseTokenName]) {
                  pairs[dstNetworkId][pair.baseTokenName].add(
                    pair.quoteTokenName,
                  );
                } else {
                  pairs[dstNetworkId][pair.baseTokenName] = new Set([
                    pair.quoteTokenName,
                  ]);
                }
              });
            }
          });

          if (tokenAddresses.size > 0) {
            tokenAddresses.forEach((address) =>
              _crossChainAddresses.push({ address, networkId: dstNetworkId }),
            );
          }
        },
      );

      setCrossChainAddresses(_crossChainAddresses);
      setCrossChainIds([..._crossChainIds]);
      setCrossChainPairs(pairs);
      setCrossChainBaseTokenList([..._baseToken]);
    }
  }, [crossChainPriceLevels]);

  useEffect(() => {
    if (crossChainAddresses.length) {
      getCrossChainTokenBalances(crossChainAddresses);
    }
  }, [crossChainAddresses]);

  useEffect(() => {
    if (crossChainIds.length) {
      getDstNetworkOptions(crossChainIds);
    }
  }, [crossChainIds]);

  const balances = connectedChainTokenBalances
    .concat(crossChainTokenBalances)
    .filter((t) => !!configuration?.trading?.tokens?.[t.networkId][t.address])
    .map((t) => {
      const { symbol, decimals } =
        configuration?.trading?.tokens[t.networkId][t.address] || {};

      if (symbol && decimals) {
        // native ETH on Arbitrum (0x000...) is represented as AETH in the trading pairs/price levels returned
        // by Hashflow. We need to map it back to ETH for the token price and logo. AETH is
        // 'Aave Ethereum' in the tokenPrices and tokenLogos objects.
        const { name, priceUsd } =
          tokenPrices?.[
            t.networkId === 42161 && symbol === 'AETH' ? 'ETH' : symbol
          ] || {};
        const decimalBalance = convertBaseUnitsToDecimal(t.balance, decimals);
        return {
          address: t.address,
          decimals: Number(decimals),
          balance: decimalBalance,
          symbol,
          name,
          priceUsd,
          usdValue: mult(priceUsd, decimalBalance, 2),
          logo: tokenLogos?.[
            t.networkId === 42161 && symbol === 'AETH' ? 'ETH' : symbol
          ].logo,
          networkId: t.networkId,
        };
      }
    });

  let tokenInfo = {};
  balances.forEach((t) => {
    if (!tokenInfo[t.networkId]) {
      tokenInfo[t.networkId] = {};
    }
    tokenInfo[t.networkId][t.symbol] = t;
  });

  return {
    baseToken,
    setBaseToken,
    baseTokenAmount,
    setBaseTokenAmount,
    baseTokenList,
    priceLevels,
    singleChainPairs,
    crossChainPriceLevels,
    crossChainPairs,
    dstNetworkId,
    setDstNetworkId,
    dstNetworkIds,
    getDstNetworkOptions,
    gasPrices,
    disableGas,
    refresh,
    tokenInfo,
    crossChainBaseTokenList,
  };
};
