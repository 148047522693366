import { PageHeader, TradingIcon } from '@tokensoft-web/common-ui';

export const TradingHeader = () => {
  return (
    <PageHeader
      title={'Tokensoft Trading'}
      icon={<TradingIcon className='large primary' />}
    />
  );
};

export default TradingHeader;
