import { Card } from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  fixAndTrim,
  useNetworks,
  useWallet,
} from '@tokensoft-web/common-utils';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { useTrading } from '../../contexts/trading/trading-context';
import '../../pages/trading/trading.css';

const TradePending = ({
  renderButtonText,
  tradeButtonDisabled,
  onTradeButtonClick,
  quote,
  onCancelTrade,
  cancelTradeDisabled,
}) => {
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const { baseToken, quoteToken, dstNetworkId, tokenInfo, crossChainTrade } =
    useTrading();

  const network = getNetworkDetails(connectedChainId);
  const dstNetwork = getNetworkDetails(
    crossChainTrade ? dstNetworkId : connectedChainId,
  );

  const baseTokenInfo = tokenInfo[connectedChainId];
  const quoteTokenInfo =
    tokenInfo[crossChainTrade ? dstNetworkId : connectedChainId];
  const bt = baseTokenInfo?.[baseToken];
  const qt = quoteTokenInfo?.[quoteToken];

  return (
    <Card className='w-128 exchange-submitted-pending'>
      <div className='flex items-center flex-grow title-text'>
        APPROVE TRANSACTION
      </div>
      <div className='mt-2 '>Please confirm the trade in your wallet.</div>
      <div className='flex mt-10'>
        <div className='flex flex-col items-center ml-8'>
          <div className='flex justify-center mb-3'>
            <div className='p-2 rounded-full'>
              {bt?.logo ? (
                <>
                  <img
                    className='rounded-full'
                    src={bt.logo}
                    width='48'
                    height='48'
                  />
                </>
              ) : (
                <div className='no-logo-token bg-neutral rounded-full flex items-center justify-center text-lg'>
                  {bt.symbol.charAt(0)}
                </div>
              )}
            </div>
          </div>
          <div className='high-contrast'>
            {`${fixAndTrim(
              convertBaseUnitsToDecimal(
                quote.baseTokenAmountWithFee,
                bt.decimals,
                6,
              ),
            )} ${bt.symbol}`}
          </div>
          <div className='text-xs text-neutral-medium'>
            {network.name} Network
          </div>
        </div>
        <div className='flex justify-center items-center mb-12 grow transaction-bg'>
          <div className='flex p-2 rounded-full high-contrast'>
            <FaArrowRight />
          </div>
        </div>
        <div className='flex flex-col items-center mr-6'>
          <div className='flex justify-center mb-3'>
            <div className='p-2 rounded-full'>
              {qt?.logo ? (
                <>
                  <img
                    className='rounded-full'
                    src={qt.logo}
                    width='48'
                    height='48'
                  />
                </>
              ) : (
                <div className='no-logo-token bg-neutral rounded-full flex items-center justify-center text-lg'>
                  {qt.symbol.charAt(0)}
                </div>
              )}
            </div>
          </div>
          <div className='high-contrast'>
            {quote
              ? `${fixAndTrim(
                  convertBaseUnitsToDecimal(
                    quote.quoteData.quoteTokenAmount,
                    qt.decimals,
                    6,
                  ),
                )} ${qt.symbol}`
              : ''}
          </div>
          <div className='text-xs text-neutral-medium'>
            {dstNetwork.name} Network
          </div>
        </div>
      </div>
      <div>
        <button
          className='btn btn-primary w-full mt-10'
          disabled={tradeButtonDisabled}
          onClick={onTradeButtonClick}
        >
          {tradeButtonDisabled ? (
            <div className='flex w-full items-center justify-center'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>
              <span className='pl-2'>{renderButtonText()}</span>
            </div>
          ) : (
            renderButtonText()
          )}
        </button>
        <div className='flex justify-center'>
          <button
            className='pt-4 underline font-extralight text-neutral-medium'
            onClick={onCancelTrade}
            disabled={cancelTradeDisabled}
          >
            Cancel Trade
          </button>
        </div>
      </div>
    </Card>
  );
};

export default TradePending;
