import { ButtonRow } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { Link } from 'react-router-dom';
import TradingHeader from './trading-header';

const TradingRegionRestriction = () => {
  const { pageEvent } = useAnalytics();
  pageEvent('trade', 'tradeRestrictedRegion');

  return (
    <>
      <TradingHeader />

      <div className='mb-12'>
        <div>
          <p className={'text-2xl font-bold my-8'}>
            Sorry, trading is not available in your region.
          </p>
          <p className='mb-8'>
            Unfortunately, your profile is currently restricted from trading on
            the Tokensoft platform.
          </p>
        </div>
      </div>

      <ButtonRow place={'start'}>
        <Link className='btn btn-primary' to='/'>
          Dashboard
        </Link>
      </ButtonRow>
    </>
  );
};

export default TradingRegionRestriction;
