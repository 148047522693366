import { createContext, useContext } from 'react';

interface TradingProps {
  // todo
}

export const TradingContext = createContext(undefined);
TradingContext.displayName = 'TradingContext';

export const useTrading = () => {
  const context = useContext(TradingContext);

  if (!context) {
    throw new Error(
      'TradingProvider context is undefined, please verify you are calling useTrading() as child of a <TradingProvider> component.',
    );
  }

  return context;
};
