import { ButtonRow } from '@tokensoft-web/common-ui';
import { useAnalytics } from '@tokensoft-web/common-utils';
import { FC } from 'react';
import TradingHeader from './trading-header';

interface TradingIntroProps {
  onContinue?: Function;
}

const TradingIntro: FC<TradingIntroProps> = ({ onContinue }) => {
  const { pageEvent } = useAnalytics();
  pageEvent('trade', 'loadTradeIntro');

  const handleClick = () => {
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <>
      <TradingHeader />

      <div className='mb-12'>
        <img
          className={'mb-12'}
          src='https://s3.amazonaws.com/media.tokensoft.io/images/trading+banner.png'
          alt={'Tokensoft Trading'}
        />
        <p className={'text-2xl font-bold mb-8'}>
          Congrats, you've been granted early access!
        </p>
        <p className={'mb-8'}>
          Tokensoft Trading is powered by the Hashflow protocol. Tokensoft
          Trading is natively cross-chain, more efficient and more performant
          versus traditional DeFi trading protocols. Tokensoft users can now
          trade with a higher level of security with a peer-to-peer,
          self-custody model.
        </p>
        <p>
          Please make sure to submit your feedback via the{' '}
          <a
            className='underline'
            href={'https://tokensoft.typeform.com/to/WzsBCsj0'}
            target='_blank'
          >
            Tokensoft Trading: Beta Release Feedback form
          </a>
          . You're also invited to join our{' '}
          <a
            className='underline'
            href={'https://t.me/+LqY30D8ZRng2N2Ex'}
            target='_blank'
          >
            Early Access: Tokensoft Trading Telegram Group
          </a>
          . However, the size of this group is limited and may fill.
        </p>
      </div>

      <ButtonRow place={'start'}>
        <button className='btn btn-primary' onClick={() => handleClick()}>
          Start Trading
        </button>
      </ButtonRow>
    </>
  );
};

export default TradingIntro;
