import { Card } from '@tokensoft-web/common-ui';
import {
  convertBaseUnitsToDecimal,
  fixAndTrim,
  getTruncatedAddress,
  getTxUrl,
  useNetworks,
  useWallet,
} from '@tokensoft-web/common-utils';
import { useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { useTrading } from '../../contexts/trading/trading-context';
import '../../pages/trading/trading.css';
import { getPaymentMethodUrlByAddress } from '../../utils/network';

const TradeDetails = ({
  baseTokenInfo,
  quoteTokenInfo,
  tradeSingleHopEventData,
  crossChainTradeEventData,
  handleTransactionFinished,
  quote,
  resetQuote,
  crossChainTradeReceipt,
}) => {
  const { connectedChainId } = useWallet();
  const { getNetworkDetails } = useNetworks();
  const { baseToken, quoteToken, dstNetworkId, crossChainTrade } = useTrading();
  const [baseTokenTotal, setBaseTokenTotal] = useState(null);

  const network = getNetworkDetails(connectedChainId);
  const dstNetwork = getNetworkDetails(dstNetworkId);

  const bt = baseTokenInfo[baseToken];
  const qt = quoteTokenInfo[quoteToken];

  useEffect(() => {
    setBaseTokenTotal(quote?.baseTokenAmountWithFee);
    resetQuote();
  }, []);

  return (
    <Card className='w-128 exchange-submitted-pending flex flex-col high-contrast'>
      <div className='flex items-center flex-grow title-text'>
        TRANSACTION DETAILS
      </div>
      <div className='text-base text-neutral-medium mb-5'>
        {`${fixAndTrim(
          convertBaseUnitsToDecimal(baseTokenTotal || '0', bt.decimals),
          6,
        )} ${baseToken} to ${fixAndTrim(
          convertBaseUnitsToDecimal(
            crossChainTrade
              ? crossChainTradeEventData.parsedLog.args.quoteTokenAmount.toString()
              : tradeSingleHopEventData.args[
                  '0'
                ].maxQuoteTokenAmount.toString(),
            qt.decimals,
          ),
          6,
        )} ${quoteToken}`}
      </div>
      <div className='flex flex-col pb-3 border-b border-solid border-b-neutral-medium mb-3'>
        {crossChainTrade ? (
          <>
            <div className='text-xs text-neutral-medium pb-1'>
              Source Network
            </div>
            <div className='flex items-center pb-3'>
              <img
                className='rounded-full mr-2'
                src={`${network.logoUri}`}
                width='18'
                height='18'
              />
              <div className='grow'>{network.name}</div>
              <div className='flex text-sm'>
                <a
                  className='flex justify-center items-center'
                  target='_blank'
                  href={getTxUrl(
                    crossChainTradeReceipt.transactionHash,
                    getNetworkDetails(connectedChainId),
                  )}
                >
                  {`${getTruncatedAddress(
                    crossChainTradeReceipt.transactionHash,
                  )}`}{' '}
                  <BiLinkExternal className='ml-2' size={14} />
                </a>
              </div>
            </div>
            <div className='text-xs text-neutral-medium pb-1'>
              Destination Network
            </div>
            <div className='flex items-center pb-3'>
              <img
                className='rounded-full mr-2'
                src={`${dstNetwork.logoUri}`}
                width='18'
                height='18'
              />
              <div className='grow'>{dstNetwork.name}</div>
              <div className='flex text-sm'>
                <a
                  className='flex justify-center items-center'
                  target='_blank'
                  href={getTxUrl(
                    crossChainTradeEventData.log.transactionHash,
                    getNetworkDetails(dstNetworkId),
                  )}
                >
                  {`${getTruncatedAddress(
                    crossChainTradeEventData.log.transactionHash,
                  )}`}{' '}
                  <BiLinkExternal className='ml-2' size={14} />
                </a>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='text-xs text-neutral-medium pb-1'>Network</div>
            <div className='flex items-center'>
              <img
                className='rounded-full mr-2'
                src={`${network.logoUri}`}
                width='18'
                height='18'
              />
              <div className='grow'>{network.name}</div>
              <div className='flex text-sm'>
                <a
                  className='flex justify-center items-center'
                  target='_blank'
                  href={getTxUrl(
                    tradeSingleHopEventData.transactionHash,
                    getNetworkDetails(connectedChainId),
                  )}
                >
                  {`${getTruncatedAddress(
                    tradeSingleHopEventData.transactionHash,
                  )}`}{' '}
                  <BiLinkExternal className='ml-2' size={14} />
                </a>
              </div>
            </div>
          </>
        )}
      </div>

      <div className='flex flex-col pb-3 border-b border-solid border-b-neutral-medium mb-7 mt-4'>
        <div className='text-xs text-neutral-medium pb-1'>
          Transaction Status
        </div>
        <div className='flex text-success-medium'>
          <div className='grow'>Success</div>
        </div>
      </div>
      <div>
        <div className='text-sm text-neutral-medium pb-2'>Tokens:</div>
        <div className='flex'>
          <div className='grow'>{baseToken}</div>
          <div className='text-sm flex justify-center items-center'>
            <a
              className='flex justify-center items-center'
              target='_blank'
              href={getPaymentMethodUrlByAddress(
                bt.address,
                getNetworkDetails(connectedChainId),
              )}
            >
              {getTruncatedAddress(bt.address)}
              <BiLinkExternal className='ml-2' size={14} />
            </a>
          </div>
        </div>
        <div className='flex'>
          <div className='grow'>{quoteToken}</div>
          <div className='text-sm flex justify-center items-center'>
            <a
              className='flex justify-center items-center'
              target='_blank'
              href={getPaymentMethodUrlByAddress(
                qt.address,
                getNetworkDetails(
                  crossChainTrade ? dstNetworkId : connectedChainId,
                ),
              )}
            >
              {getTruncatedAddress(qt.address)}
              <BiLinkExternal className='ml-2' size={14} />
            </a>
          </div>
        </div>
        <button
          className='btn btn-primary w-full mt-6'
          onClick={handleTransactionFinished}
        >
          New Trade
        </button>
      </div>
    </Card>
  );
};

export default TradeDetails;
